import React, { useState, useEffect } from "react";
import CountryCode from "../Dropdowns/CountryCode";
import axios from "axios";

function TravelInformation(props) {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState();

  function onSelectChanged(e) {
    setError();

    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[e.target.id] = e.target.value;
    console.log(formDataCopy);

    setFormData(formDataCopy);
  }

  function request(data) {
    axios.defaults.withCredentials = true;
    axios
      .post("https://www.trustyvisas.com/api/travelinformation.php", data)
      .then(function (response) {
        if (response.data) {
          console.log(formData);
          setFormData(response.data);
        } else {
          setFormData({});
        }

        if (data) {
          window.location.href = "/elegibility";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    request();
  }, []);

  function updateFormData(e) {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[e.target.id] = e.target.value;
    console.log(formDataCopy);
    setFormData(formDataCopy);
  }

  function next() {
    if (formData?.transiting === "" || !formData?.transiting) {
      setError({
        field: "transiting",
        message: "Please select an option",
      });
      return;
    }
    if (
      formData?.transiting === "No" &&
      (!formData.pointOfContact || formData?.pointOfContact.length === 0)
    ) {
      setError({
        field: "pointOfContact",
        message: "Point of contact cannot be empty",
      });
      return;
    }
    if (
      formData?.transiting === "No" &&
      (!formData.addressLine1 || formData?.addressLine1.length === 0)
    ) {
      setError({
        field: "addressLine1",
        message: "Address Line 1 cannot be empty",
      });
      return;
    }
    if (
      formData?.transiting === "No" &&
      (!formData.city || formData?.city.length === 0)
    ) {
      setError({
        field: "city",
        message: "City cannot be empty",
      });
      return;
    }
    if (
      formData?.transiting === "No" &&
      (!formData.state || formData?.state.length === 0)
    ) {
      setError({
        field: "state",
        message: "Please select a state",
      });
      return;
    }
    if (
      formData?.transiting === "No" &&
      (!formData.telephoneNumber || formData?.telephoneNumber.length === 0)
    ) {
      setError({
        field: "telephoneNumber",
        message: "Telephone Number cannot be empty",
      });
      return;
    }
    if (!formData.familyName || formData?.familyName.length === 0) {
      setError({
        field: "familyName",
        message: "Family Name cannot be empty",
      });
      return;
    }
    if (!formData.firstName || formData?.firstName.length === 0) {
      setError({
        field: "firstName",
        message: "First Name cannot be empty",
      });
      return;
    }
    if (!formData.countryCode || formData?.countryCode.length === 0) {
      setError({
        field: "countryCode",
        message: "Please select a country code",
      });
      return;
    }
    if (
      !formData.telephoneNumberEC ||
      formData?.telephoneNumberEC.length === 0
    ) {
      setError({
        field: "telephoneNumberEC",
        message: "Telephone Number cannot be empty",
      });
      return;
    }
    if (!formData.email || formData?.email.length === 0) {
      setError({
        field: "email",
        message: "Email cannot be empty",
      });
      return;
    }

    request(formData);
  }

  return (
    <form>
      <div className="row">
        <div className="col-12">
          <div className="pt-4 pb-4">
            <h5>
              <b style={{ fontSize: "35px" }}>Travel Information</b>
            </h5>
          </div>
        </div>
        <div className="col-xs-12 col-md-4 pb-5 d-block d-md-none">
          <div className="pt-3" style={{ textAlign: "end" }}>
            Progress (Page 4/7)
            <div class="progress" style={{ width: "100%" }}>
              <div
                class="progress-bar progress-bar-striped"
                role="progressbar"
                style={{ width: "80%" }}
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="exampleFormControlSelect1">
          Is your travel to the US occurring in transit to another country? *
        </label>
        <select
          class="form-control"
          id="transiting"
          onChange={(e) => onSelectChanged(e)}
          value={formData?.transiting}
        >
          <option value="">Select...</option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
        <small class="form-text " style={{ color: "red" }}>
          {error?.field === "transiting" && error.message}
        </small>
      </div>

      {formData?.transiting === "No" && (
        <div className="col">
          <div className="row">
            <div className="col-8">
              <div className="pt-4 pb-4">
                <h5>
                  <b>U.S. POINT OF CONTACT INFORMATION</b>
                </h5>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">
              US Point of Contact (ie Hotel Name) *
            </label>
            <input
              type="text"
              class="form-control"
              id="pointOfContact"
              placeholder="Point of contact"
              onChange={(e) => [updateFormData(e), setError()]}
              value={formData.pointOfContact}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "pointOfContact" && error.message}
            </small>
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Address Line 1 *</label>
            <input
              type="text"
              class="form-control"
              id="addressLine1"
              placeholder="Address Line 1"
              onChange={(e) => [updateFormData(e), setError()]}
              value={formData.addressLine1}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "addressLine1" && error.message}
            </small>
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Address Line 2 </label>
            <input
              type="text"
              class="form-control"
              placeholder="Address Line 2"
              id="addressLine2"
              onChange={(e) => [updateFormData(e)]}
              value={formData?.addressLine2}
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Apartment Number</label>
            <input
              type="text"
              class="form-control"
              placeholder="Apartment Number"
              id="apartmentNumber"
              onChange={(e) => [updateFormData(e)]}
              value={formData?.apartmentNumber}
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">City *</label>
            <input
              type="text"
              id="city"
              class="form-control"
              placeholder="City"
              onChange={(e) => [updateFormData(e), setError()]}
              value={formData?.city}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "city" && error.message}
            </small>
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">
              State / Province / Region *
            </label>
            <select
              class="form-control"
              id="state"
              onChange={(e) => [setError(), onSelectChanged(e)]}
              value={formData?.state}
            >
              <option value="">Select</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "state" && error.message}
            </small>
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Telephone Number *</label>
            <input
              type="text"
              class="form-control"
              placeholder="Telephone Number"
              id="telephoneNumber"
              onChange={(e) => [setError(""), updateFormData(e)]}
              value={formData?.telephoneNumber}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "telephoneNumber" && error.message}
            </small>
          </div>
          <div className="row">
            <div className="col-8">
              <div className="pt-4 pb-4">
                <h5>
                  <b>Address While in the US</b>
                </h5>
              </div>
            </div>

            <div className="col-12">
              <p _ngcontent-xft-c55="">
                The address where you will be staying in the U.S. is optional to
                complete the application. If multiple locations are planned,
                enter the first address. If the complete address is not known,
                enter the name of the hotel or location you will visit.
              </p>
            </div>

            <div className="col-12">
              <div className="row pt-4">
                <div _ngcontent-xft-c55="" class="col-xs-7 col-md-10">
                  <label
                    _ngcontent-xft-c55=""
                    id="sameAddressSwitchLabel"
                    for="sameAddressSwitch"
                  >
                    Is your Address While in the U.S. same as the U.S. Point of
                    Contact Address listed above?
                  </label>
                </div>
                <div _ngcontent-odk-c55="" class="col-xs-5 col-md-2 yesno">
                  <select
                    id="sameAddress"
                    class="form-control"
                    onChange={(e) => [setError(), onSelectChanged(e)]}
                    value={formData?.sameAddress}
                  >
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
              </div>
              {formData?.sameAddress === "No" && (
                <>
                  <div className="row pt-4">
                    <div className="col-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">
                          Address Line 1 *
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="addressLine1US"
                          placeholder="Address Line 1"
                          onChange={(e) => [updateFormData(e), setError()]}
                          value={formData.addressLine1US}
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleFormControlInput1">
                          Address Line 2{" "}
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Address Line 2"
                          id="addressLine2US"
                          onChange={(e) => [updateFormData(e)]}
                          value={formData?.addressLine2US}
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleFormControlInput1">
                          Apartment Number
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Apartment Number"
                          id="apartmentNumberUS"
                          onChange={(e) => [updateFormData(e)]}
                          value={formData?.apartmentNumberUS}
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleFormControlInput1">City *</label>
                        <input
                          type="text"
                          id="cityUS"
                          class="form-control"
                          placeholder="City"
                          onChange={(e) => [updateFormData(e), setError()]}
                          value={formData?.cityUS}
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleFormControlInput1">
                          State / Province / Region *
                        </label>
                        <select
                          class="form-control"
                          id="stateUS"
                          onChange={(e) => [setError(), onSelectChanged(e)]}
                          value={formData?.stateUS}
                        >
                          <option value="">Select</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-8">
          <div className="pt-4 pb-4">
            <h5>
              <b>Emergency Contact Information (In or outside United States)</b>
            </h5>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="exampleFormControlInput1">Family Name *</label>
        <input
          type="text"
          class="form-control"
          placeholder="Family Name"
          id="familyName"
          onChange={(e) => [setError(), updateFormData(e)]}
          value={formData?.familyName}
        />
        <small class="form-text " style={{ color: "red" }}>
          {error?.field === "familyName" && error.message}
        </small>
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">First / Given Name *</label>
        <input
          type="text"
          class="form-control"
          id="firstName"
          placeholder="First / Given Name"
          onChange={(e) => [setError(), updateFormData(e)]}
          value={formData?.firstName}
        />
        <small class="form-text " style={{ color: "red" }}>
          {error?.field === "firstName" && error.message}
        </small>
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Telephone Country Code *</label>
        <CountryCode
          id="countryCode"
          changed={(e) => [setError(), updateFormData(e)]}
          value={formData?.countryCode}
        />
        <small class="form-text " style={{ color: "red" }}>
          {error?.field === "countryCode" && error.message}
        </small>
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Telephone Number *</label>
        <input
          type="text"
          class="form-control"
          placeholder="Telephone Number"
          id="telephoneNumberEC"
          onChange={(e) => [setError(""), updateFormData(e)]}
          value={formData?.telephoneNumberEC}
        />
        <small class="form-text " style={{ color: "red" }}>
          {error?.field === "telephoneNumberEC" && error.message}
        </small>
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Email Address *</label>
        <input
          type="text"
          class="form-control"
          id="email"
          placeholder="Email"
          onChange={(e) => [setError(), updateFormData(e)]}
          value={formData?.email}
        />
        <small class="form-text " style={{ color: "red" }}>
          {error?.field === "email" && error.message}
        </small>
      </div>

      <div class="modal-footer">
        <button
          onClick={() => (window.location.href = "/personalinfo")}
          type="button"
          class="btn btn-primary"
        >
          {"<"} Previous Page
        </button>
        <button onClick={() => next()} type="button" class="btn btn-primary">
          Next Page {">"}
        </button>
      </div>
    </form>
  );
}
export default TravelInformation;
