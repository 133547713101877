import { atom } from "recoil";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const formAtom = atom({
  key: "form", // unique ID (with respect to other atoms/selectors)
  default: {
    visible: false,
    currentPage: 1,
    data: [{}, {}, {}, {}, {}, {}, {}, {}],
  },
  dangerouslyAllowMutability: true,
  effects: [localStorageEffect("form_data")],
});
