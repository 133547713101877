import React, { useEffect, useState } from "react";
import { formAtom } from "../Atoms";
import { useRecoilState } from "recoil";
const axios = require("axios");

function FormModal(props) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState();
  const [form, setForm] = useRecoilState(formAtom);

  const [search, setSearch] = useState("");

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    console.log(search);
    setSearch(search);
    test(search);
  }, []);

  async function test(id) {
    console.log("start timer");
    checkStatus(id);
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }

  async function test2() {
    console.log("start timer");
    await new Promise((resolve) => setTimeout(resolve, 30000));
    checkStatus(search);
  }

  function checkStatus(funcId) {
    axios
      .get("https://www.trustyvisas.com/check_payment_status.php" + funcId)
      .then(function (response) {
        console.log(response.data);

        var data = JSON.parse(response.data);

        console.log(data.result.code);

        var reg1 = /^(000\.000\.|000\.100\.1|000\.[36])/;
        if (reg1.test(data.result.code)) {
          localStorage.removeItem("form_data");
          setForm({
            visible: false,
            currentPage: 1,
            data: [{}, {}, {}, {}, {}, {}, {}, {}],
          });
          setSuccess(true);
          return;
        }

        var reg1 = /^(000\.400\.0[^3]|000\.400\.[0-1]{2}0)/;
        if (reg1.test(data.result.code)) {
          localStorage.removeItem("form_data");
          setForm({
            visible: false,
            currentPage: 1,
            data: [{}, {}, {}, {}, {}, {}, {}, {}],
          });
          setSuccess(true);
          return;
        }

        var reg1 = /^(000\.200)/;
        if (reg1.test(data.result.code)) {
          test2();
          return;
        }

        setError(data.result.description);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.71)",
        overflow: "scroll",
      }}
      id="apply-modal"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-color-1">
            <figure class="logo-box">
              <a href="/">
                <img src="assets/images/logo.png" alt="" />
              </a>
            </figure>

            <button
              type="button"
              style={{ color: "white" }}
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.close()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {error === "" && success === undefined && (
              <>
                <div
                  className="row"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/200w.gif" style={{ height: "150px" }} />
                </div>
                <div
                  className="row p-5"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                  }}
                >
                  We are processing your payment, this may take up to 60
                  seconds. Please do not close or leave this page.
                </div>
              </>
            )}

            {error === "" && success && (
              <>
                <div
                  className="row"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/accept.png" style={{ height: "100px" }} />
                </div>
                <div
                  className="row pt-5"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                  }}
                >
                  Thank you, Your payment was Sucessful.
                </div>
                <div
                  className="row p-5"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                  }}
                >
                  <button
                    class="btn btn-primary bg-color-1"
                    style={{ width: "300px" }}
                    onClick={() => props.close()}
                  >
                    Close
                  </button>
                </div>
              </>
            )}

            {error !== "" && (
              <>
                <div
                  className="row"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/cancel.png" style={{ height: "100px" }} />
                </div>
                <div
                  className="row pt-5"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                  }}
                >
                  {error}
                </div>
                <div
                  className="row p-5"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                  }}
                >
                  <button
                    class="btn btn-primary bg-color-1"
                    style={{ width: "300px" }}
                    onClick={() => props.close()}
                  >
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default FormModal;
