import React, { useEffect, useState } from "react";
import { formAtom } from "../../Atoms";
import { useRecoilState } from "recoil";
import axios from "axios";
import CountryDropdown from "../CountryDropdown";
import CountryCode from "../Dropdowns/CountryCode";

function PersonalInformation(props) {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState();
  const [errorNested, setErrorNested] = useState();

  function onSelectChanged(e) {
    setError();
    setErrorNested();
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[e.target.id] = e.target.value;
    console.log(formDataCopy);

    if (e.target.id === "hasAlias" || e.target.id === "otherPassport") {
      if (e.target.value === "Yes") {
        formDataCopy[e.target.id + "List"] = [{}];
      } else {
        formDataCopy[e.target.id + "List"] = [];
      }
      console.log(formDataCopy);
    }

    setFormData(formDataCopy);
  }

  function request(data) {
    axios.defaults.withCredentials = true;
    axios
      .post("https://www.trustyvisas.com/api/personalinformation.php", data)
      .then(function (response) {
        if (response.data) {
          console.log(formData);
          setFormData(response.data);
        } else {
          setFormData({ contactList: [{}] });
        }

        if (data) {
          window.location.href = "/travelinfo";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    request();
  }, []);

  function updateFormData(e) {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[e.target.id] = e.target.value;
    console.log(formDataCopy);
    setFormData(formDataCopy);
  }
  function updateFormDataNested(e, key, id) {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[id][key][e.target.id] = e.target.value;
    console.log(formDataCopy);
    setFormData(formDataCopy);
  }

  function addRow(id) {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[id][formDataCopy[id].length] = {};
    console.log(formDataCopy);
    setFormData(formDataCopy);
  }
  function removeRow(id, key) {
    console.log(key + " " + id);
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[id].splice(key, 1);
    console.log(formDataCopy);
    setFormData(formDataCopy);
  }
  function next() {
    if (!formData.hasAlias) {
      setError({
        field: "hasAlias",
        message: "Please select an option",
      });
      return;
    }
    if (!formData.otherPassport) {
      setError({
        field: "otherPassport",
        message: "Please select an option",
      });
      return;
    }
    for (let i = 0; i < formData?.hasAliasList?.length; i++) {
      if (
        formData.hasAliasList[i].aliasGivenName === "" ||
        !formData.hasAliasList[i].aliasGivenName
      ) {
        setErrorNested({
          id: "hasAlias",
          key: i,
          message: "Cannot be empty",
        });
        return;
      }
      if (
        formData.hasAliasList[i].aliasLastName === "" ||
        !formData.hasAliasList[i].aliasLastName
      ) {
        setErrorNested({
          id: "hasAliasLast",
          key: i,
          message: "Cannot be empty",
        });
        return;
      }
    }

    for (let i = 0; i < formData.otherPassportList.length; i++) {
      if (
        formData.otherPassportList[i].issuingCountry === "" ||
        !formData.otherPassportList[i].issuingCountry
      ) {
        setErrorNested({
          id: "issuingCountry",
          key: i,
          message: "Please Select an option",
        });
        return;
      }

      if (
        formData.otherPassportList[i].documentType === "" ||
        !formData.otherPassportList[i].documentType
      ) {
        setErrorNested({
          id: "documentType",
          key: i,
          message: "Please Select an option",
        });
        return;
      }
      if (
        formData.otherPassportList[i].documentNumber === "" ||
        !formData.otherPassportList[i].documentNumber
      ) {
        setErrorNested({
          id: "documentNumber",
          key: i,
          message: "Please enter document number",
        });
        return;
      }
      if (
        formData.otherPassportList[i].expirationYear === "" ||
        !formData.otherPassportList[i].expirationYear
      ) {
        setErrorNested({
          id: "expirationYear",
          key: i,
          message: "Please enter expiration year",
        });
        return;
      }
    }
    for (let i = 0; i < formData.contactList.length; i++) {
      if (
        formData.contactList[i].telephoneType === "" ||
        !formData.contactList[i].telephoneType
      ) {
        setErrorNested({
          id: "telephoneType",
          key: i,
          message: "Please select an option",
        });
        return;
      }
      if (
        formData.contactList[i].telephoneCountryCode === "" ||
        !formData.contactList[i].telephoneCountryCode
      ) {
        console.log("**ERRROR");
        setErrorNested({
          id: "telephoneCountryCode",
          key: i,
          message: "Please enter a valid country code",
        });
        return;
      }
      if (
        formData.contactList[i].telephoneNumber === "" ||
        !formData.contactList[i].telephoneNumber
      ) {
        setErrorNested({
          id: "telephoneNumber",
          key: i,
          message: "Please enter a valid telephone number",
        });
        return;
      }
      if (
        formData.contactList[i].addressLine1 === "" ||
        !formData.contactList[i].addressLine1
      ) {
        setErrorNested({
          id: "addressLine1",
          key: i,
          message: "Address Line 1 cannot be empty",
        });
        return;
      }
      if (
        formData.contactList[i].city === "" ||
        !formData.contactList[i].city
      ) {
        setErrorNested({
          id: "city",
          key: i,
          message: "Please enter a valid city",
        });
        return;
      }
      if (
        formData.contactList[i].state === "" ||
        !formData.contactList[i].state
      ) {
        setErrorNested({
          id: "state",
          key: i,
          message: "Please enter a valid state",
        });
        return;
      }
      if (
        formData.contactList[i].country === "" ||
        !formData.contactList[i].country
      ) {
        setErrorNested({
          id: "country",
          key: i,
          message: "Please select a country",
        });
        return;
      }
    }

    if (formData.hasMembership === "" || !formData.hasMembership) {
      setError({
        field: "hasMembership",
        message: "Please select an option",
      });
      return;
    }
    if (
      formData?.hasMembership === "Yes" &&
      (formData.membershipNuber === "" || !formData.membershipNumber)
    ) {
      setError({
        field: "memberNumber",
        message: "Please enter membership number",
      });
      return;
    }
    if (formData.parent1LastName === "" || !formData.parent1LastName) {
      setError({
        field: "parent1LastName",
        message: "Last Name is not valid",
      });
      return;
    }

    if (formData.parent1FirstName === "" || !formData.parent1FirstName) {
      setError({
        field: "parent1FirstName",
        message: "First Name is not valid",
      });
      return;
    }

    if (formData.parent2LastName === "" || !formData.parent2LastName) {
      setError({
        field: "parent2LastName",
        message: "Last Name is not valid",
      });
      return;
    }
    if (formData.parent2FirstName === "" || !formData.parent2FirstName) {
      setError({
        field: "parent2FirstName",
        message: "First Name is not valid",
      });
      return;
    }
    if (formData.employment === "" || !formData.employment) {
      setError({
        field: "employment",
        message: "Please select an option",
      });
      return;
    }

    if (formData.employment === "Yes") {
      if (formData?.employerName === "" || !formData?.employerName) {
        setError({
          field: "employerName",
          message: "Please enter Employer Name",
        });
        return;
      }
      if (
        formData?.employerAddressLine1 === "" ||
        !formData?.employerAddressLine1
      ) {
        setError({
          field: "employerAddressLine1",
          message: "Please enter Address Line 1",
        });
        return;
      }
      if (formData?.employerCity === "" || !formData?.employerCity) {
        setError({
          field: "employerCity",
          message: "Please enter Employer City",
        });
        return;
      }
      if (formData?.employerState === "" || !formData?.employerState) {
        setError({
          field: "employerState",
          message: "Please enter Employer State",
        });
        return;
      }
      if (formData?.employerCountry === "" || !formData?.employerCountry) {
        setError({
          field: "employerCountry",
          message: "Please enter Employer Country",
        });
        return;
      }
    }

    request(formData);
  }
  return (
    <form>
      <div className="row">
        <div className="col-8">
          <div className="pt-4 pb-4">
            <h5 style={{ fontSize: "35px" }}>
              <b>Personal Information</b>
            </h5>
          </div>
          <span _ngcontent-fkl-c84="">
            Please provide all responses in English.
            <br />
            <span class="esta-application-required">
              Required fields are indicated by a red asterisk *.
            </span>
          </span>
        </div>
        <div className="col-xs-12 col-md-4 pb-5 d-block d-md-none">
          <div className="pt-3" style={{ textAlign: "end" }}>
            Progress (Page 3/7)
            <div class="progress" style={{ width: "100%" }}>
              <div
                class="progress-bar progress-bar-striped"
                role="progressbar"
                style={{ width: "25%" }}
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div class="form-group pt-5">
            <label for="exampleFormControlSelect1">
              Are you known by any other names or aliases? *
            </label>
            <select
              class="form-control"
              id="hasAlias"
              onChange={(e) => onSelectChanged(e)}
              value={formData?.hasAlias}
            >
              <option value="">Select...</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "hasAlias" && error.message}
            </small>
          </div>
          {formData?.hasAliasList?.map((item, key) => (
            <div className="row">
              <div className="col-12 col-lg-5">
                <div class="form-group">
                  <label for="exampleFormControlInput1">Given Name *</label>
                  <input
                    type="text"
                    id="aliasGivenName"
                    class="form-control"
                    placeholder="First Name"
                    value={formData?.hasAliasList[key].aliasGivenName ?? ""}
                    onChange={(e) => [
                      setError(),
                      updateFormDataNested(e, key, "hasAliasList"),
                    ]}
                  />
                  <small class="form-text " style={{ color: "red" }}>
                    {errorNested?.id === "hasAlias" &&
                      errorNested.key === key &&
                      errorNested.message}
                  </small>
                </div>
              </div>

              <div className="col-12 col-lg-5">
                <div class="form-group">
                  <label for="exampleFormControlInput1">Family Name *</label>
                  <input
                    type="text"
                    id="aliasLastName"
                    class="form-control"
                    placeholder="Last Name"
                    value={formData?.["hasAliasList"][key].aliasLastName}
                    onChange={(e) => [
                      setErrorNested(),
                      updateFormDataNested(e, key, "hasAliasList"),
                    ]}
                  />
                  <small class="form-text " style={{ color: "red" }}>
                    {errorNested?.id === "hasAliasLast" &&
                      errorNested.key === key &&
                      errorNested.message}
                  </small>
                </div>
              </div>
              <div className="col-12 col-lg-2">
                <div class="form-group">
                  <label
                    class="control-label"
                    style={{ color: "white" }}
                    for="btn"
                  >
                    .
                  </label>
                  {formData.hasAliasList.length > 1 && (
                    <button
                      onClick={() => [
                        removeRow("hasAliasList", key),
                        setErrorNested(),
                      ]}
                      type="button"
                      class="btn btn-danger form-control"
                    >
                      - Remove
                    </button>
                  )}
                </div>
              </div>
              <div className="col-12">
                {key + 1 === formData?.hasAliasList.length && (
                  <div className="col-12">
                    <div className="row pb-5">
                      <button
                        onClick={() => addRow("hasAliasList")}
                        type="button"
                        class="btn btn-secondary form-control"
                      >
                        + Add Another
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}

          <div class="form-group">
            <label for="exampleFormControlSelect1">
              Have you ever been issued a passport or national identity card for
              travel by any other country? *
            </label>
            <select
              class="form-control"
              id="otherPassport"
              onChange={(e) => onSelectChanged(e)}
              value={formData?.otherPassport}
            >
              <option value="">Select</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "otherPassport" && error.message}
            </small>
          </div>
          {formData.otherPassportList &&
            formData?.otherPassportList?.map((item, key) => (
              <div className="col">
                <div className="row">
                  <div className="col-3">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">
                        Issuing Country *
                      </label>
                      <CountryDropdown
                        id="issuingCountry"
                        onChange={(e) => [
                          setErrorNested(),
                          updateFormDataNested(e, key, "otherPassportList"),
                        ]}
                        value={formData?.otherPassportList[key].issuingCountry}
                      />

                      <small class="form-text " style={{ color: "red" }}>
                        {errorNested?.id === "issuingCountry" &&
                          errorNested.key === key &&
                          errorNested.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">
                        Document Type *
                      </label>
                      <select
                        class="form-control"
                        id="documentType"
                        onChange={(e) => [
                          setErrorNested(),
                          updateFormDataNested(e, key, "otherPassportList"),
                        ]}
                        value={formData?.otherPassportList[key].documentType}
                      >
                        <option value="">Select</option>
                        <option value="Passport Number">Passport Number</option>
                        <option value="Identity Card Number">
                          Identity Card Number
                        </option>
                      </select>
                      <small class="form-text " style={{ color: "red" }}>
                        {errorNested?.id === "documentType" &&
                          errorNested.key === key &&
                          errorNested.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-2">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">
                        Document Number *
                      </label>
                      <input
                        type="text"
                        id="documentNumber"
                        class="form-control"
                        placeholder="Document Number"
                        onChange={(e) => [
                          updateFormDataNested(e, key, "otherPassportList"),
                          setErrorNested(),
                        ]}
                        value={formData?.otherPassportList[key].documentNumber}
                      />
                      <small class="form-text " style={{ color: "red" }}>
                        {errorNested?.id === "documentNumber" &&
                          errorNested.key === key &&
                          errorNested.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-2">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">
                        Expirtation Year *
                      </label>
                      <input
                        type="text"
                        id="expirationYear"
                        class="form-control"
                        placeholder="Expiration Year"
                        onChange={(e) => [
                          updateFormDataNested(e, key, "otherPassportList"),
                          setErrorNested(),
                        ]}
                        value={formData?.otherPassportList[key].expirationYear}
                      />
                      <small class="form-text " style={{ color: "red" }}>
                        {errorNested?.id === "expirationYear" &&
                          errorNested.key === key &&
                          errorNested.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-lg-2">
                    <div class="form-group">
                      <label
                        class="control-label"
                        style={{ color: "white" }}
                        for="btn"
                      >
                        .
                      </label>
                      {formData.otherPassportList.length > 1 && (
                        <button
                          onClick={() => [
                            removeRow("otherPassportList", key),
                            setErrorNested(),
                          ]}
                          type="button"
                          class="btn btn-danger form-control"
                        >
                          - Remove
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    {key + 1 === formData?.otherPassportList.length && (
                      <div className="col-12">
                        <div className="row pb-5">
                          <button
                            onClick={() => addRow("otherPassportList")}
                            type="button"
                            class="btn btn-secondary form-control"
                          >
                            + Add Another
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          <div className="pt-4 pb-4">
            <h5 style={{ fontSize: "35px" }}>
              <b>Contact Information</b>
            </h5>
            <h7>Please list your contact information below.</h7>
          </div>
        </div>
      </div>

      {formData.contactList &&
        formData?.contactList?.map((item, key) => (
          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Telephone Type *</label>
                <select
                  class="form-control"
                  id="telephoneType"
                  onChange={(e) => [
                    updateFormDataNested(e, key, "contactList"),
                    setErrorNested(),
                  ]}
                  value={formData?.contactList[key].telephoneType}
                >
                  <option value="">Select</option>
                  <option value="Home">Home</option>
                  <option value="Work">Work</option>
                  <option value="Mobile">Mobile</option>
                  <option value="Other">Other</option>
                </select>
                <small class="form-text " style={{ color: "red" }}>
                  {errorNested?.id === "telephoneType" &&
                    errorNested.key === key &&
                    errorNested.message}
                </small>
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput1">
                  Telephone Country Code *
                </label>

                <CountryCode
                  id="telephoneCountryCode"
                  changed={(e) => [
                    setErrorNested(),
                    updateFormDataNested(e, key, "contactList"),
                  ]}
                  value={formData?.contactList[key].telephoneCountryCode}
                />
                <small class="form-text " style={{ color: "red" }}>
                  {errorNested?.id === "telephoneCountryCode" &&
                    errorNested.key === key &&
                    errorNested.message}
                </small>
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput1">Telephone Number *</label>
                <input
                  type="text"
                  id="telephoneNumber"
                  class="form-control"
                  placeholder="Telephone Number"
                  onChange={(e) => [
                    setErrorNested(),
                    updateFormDataNested(e, key, "contactList"),
                  ]}
                  value={formData?.contactList[key].telephoneNumber}
                />
                <small class="form-text " style={{ color: "red" }}>
                  {errorNested?.id === "telephoneNumber" &&
                    errorNested.key === key &&
                    errorNested.message}
                </small>
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput1">Address Line 1 *</label>
                <input
                  type="text"
                  id="addressLine1"
                  class="form-control"
                  placeholder="Address Line 1"
                  onChange={(e) => [
                    setErrorNested(),
                    updateFormDataNested(e, key, "contactList"),
                  ]}
                  value={formData?.contactList[key].addressLine1}
                />
                <small class="form-text " style={{ color: "red" }}>
                  {errorNested?.id === "addressLine1" &&
                    errorNested.key === key &&
                    errorNested.message}
                </small>
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput1">Address Line 2 </label>
                <input
                  type="text"
                  id="addressLine2"
                  class="form-control"
                  placeholder="Address Line 2"
                  onChange={(e) => [
                    updateFormDataNested(e, key, "contactList"),
                  ]}
                  value={formData?.contactList[key].addressLine2}
                />
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput1">Apartment Number</label>
                <input
                  type="text"
                  class="form-control"
                  id="apartmentNumber"
                  placeholder="Apartment Number"
                  onChange={(e) => [
                    updateFormDataNested(e, key, "contactList"),
                  ]}
                  value={formData?.contactList[key].apartmentNumber}
                />
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput1">City *</label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  placeholder="City"
                  onChange={(e) => [
                    setErrorNested(),
                    updateFormDataNested(e, key, "contactList"),
                  ]}
                  value={formData?.contactList[key].city}
                />
                <small class="form-text " style={{ color: "red" }}>
                  {errorNested?.id === "city" &&
                    errorNested.key === key &&
                    errorNested.message}
                </small>
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput1">
                  State / Province / Region *
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  placeholder="State / Province / Region"
                  onChange={(e) => [
                    setErrorNested(),
                    updateFormDataNested(e, key, "contactList"),
                  ]}
                  value={formData?.contactList[key].state}
                />
                <small class="form-text " style={{ color: "red" }}>
                  {errorNested?.id === "state" &&
                    errorNested.key === key &&
                    errorNested.message}
                </small>
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput1">Country *</label>
                <CountryDropdown
                  id="country"
                  onChange={(e) => [
                    updateFormDataNested(e, key, "contactList"),
                    setErrorNested(),
                  ]}
                  value={formData?.contactList[key].country}
                />
                <small class="form-text " style={{ color: "red" }}>
                  {errorNested?.id === "country" &&
                    errorNested.key === key &&
                    errorNested.message}
                </small>
              </div>
              <div className="form-group">
                <div class="form-group">
                  {formData.contactList.length > 1 && (
                    <button
                      onClick={() => [
                        removeRow("contactList", key),
                        setErrorNested(),
                      ]}
                      type="button"
                      class="btn btn-danger form-control"
                    >
                      - Remove
                    </button>
                  )}
                </div>
              </div>
              <div className="form-group">
                {key + 1 === formData?.contactList.length && (
                  <div className="col-12">
                    <div className="row pb-5">
                      <button
                        onClick={() => addRow("contactList")}
                        type="button"
                        class="btn btn-secondary form-control"
                      >
                        + Add Another
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      <div className="row">
        <div className="col-12">
          <div className="pt-4 pb-4">
            <h5 style={{ fontSize: "35px" }}>
              <b>GE Membership</b>
            </h5>
          </div>
        </div>
        <div className="col-12">
          <div class="form-group">
            <label for="exampleFormControlSelect1">
              Are you a member of the CBP Global Entry Program? *
            </label>
            <select
              class="form-control"
              id="hasMembership"
              onChange={(e) => [updateFormData(e), setError()]}
              value={formData?.hasMembership}
            >
              <option value="">Select...</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "hasMembership" && error.message}
            </small>
          </div>
        </div>

        {formData?.hasMembership === "Yes" && (
          <div className="col">
            <div class="form-group">
              <label for="exampleFormControlInput1">
                PASSID / Membership Number *
              </label>
              <input
                type="text"
                class="form-control"
                id="membershipNumber"
                placeholder="Membership Number"
                onChange={(e) => [updateFormData(e), setError()]}
                value={formData?.membershipNumber}
              />
              <small class="form-text " style={{ color: "red" }}>
                {error?.field === "memberNumber" && error.message}
              </small>
            </div>
          </div>
        )}
      </div>

      <div className="col-8 p-0">
        <div className="pt-4 pb-4">
          <h5 style={{ fontSize: "35px" }}>
            <b>Parents</b>
          </h5>
          <h7>
            Please list your parents names in the boxes to the right. All
            applicants are required to fill out this section.
          </h7>
        </div>
        <div className="row">
          <div className="col-6">
            <label for="exampleFormControlInput1">Family Name *</label>
            <input
              type="text"
              class="form-control"
              id="parent1LastName"
              placeholder="Family Name"
              value={formData?.parent1LastName}
              onChange={(e) => [updateFormData(e), setError()]}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "parent1LastName" && error.message}
            </small>
          </div>
          <div className="col-6">
            <label for="exampleFormControlInput1">First (Given) Name *</label>
            <input
              type="text"
              id="parent1FirstName"
              class="form-control"
              placeholder="Given Name"
              value={formData?.parent1FirstName}
              onChange={(e) => [updateFormData(e), setError("")]}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "parent1FirstName" && error.message}
            </small>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-6">
            <label for="exampleFormControlInput1">Family Name *</label>
            <input
              type="text"
              id="parent2LastName"
              class="form-control"
              placeholder="Family Name"
              value={formData?.parent2LastName}
              onChange={(e) => [updateFormData(e), setError("")]}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "parent2LastName" && error.message}
            </small>
          </div>
          <div className="col-6">
            <label for="exampleFormControlInput1">First (Given) Name *</label>
            <input
              type="text"
              id="parent2FirstName"
              class="form-control"
              placeholder="Given Name"
              value={formData?.parent2FirstName}
              onChange={(e) => [updateFormData(e), setError("")]}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "parent2FirstName" && error.message}
            </small>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="pt-4 pb-4">
            <h5>
              <b style={{ fontSize: "35px" }}>Employment Information</b>
            </h5>
          </div>
        </div>
        <div className="col-12">
          <div class="form-group">
            <label for="exampleFormControlSelect1">
              Do you have a current or previous employer? *
            </label>
            <select
              class="form-control"
              id="employment"
              onChange={(e) => onSelectChanged(e)}
              value={formData?.employment}
            >
              <option value="">Select ..</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "employment" && error.message}
            </small>
          </div>
        </div>

        {formData?.employment === "Yes" && (
          <div className="col">
            <div class="form-group">
              <label for="exampleFormControlInput1">Job Title</label>
              <input
                type="text"
                class="form-control"
                id="jobTitle"
                placeholder="Job Title"
                onChange={(e) => [updateFormData(e), setError()]}
                value={formData?.jobTitle}
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Employer Name *</label>
              <input
                type="text"
                id="employerName"
                class="form-control"
                placeholder="Employer Name"
                onChange={(e) => [updateFormData(e), setError()]}
                value={formData?.employerName}
              />
              <small class="form-text " style={{ color: "red" }}>
                {error?.field === "employerName" && error.message}
              </small>
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Address Line 1 *</label>
              <input
                type="text"
                id="employerAddressLine1"
                class="form-control"
                placeholder="Address Line 1"
                onChange={(e) => [updateFormData(e), setError()]}
                value={formData?.employerAddressLine1}
              />
              <small class="form-text " style={{ color: "red" }}>
                {error?.field === "employerAddressLine1" && error.message}
              </small>
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Address Line 2 </label>
              <input
                type="text"
                class="form-control"
                id="employerAddressLine2"
                placeholder="Address Line 2"
                onChange={(e) => [updateFormData(e), setError()]}
                value={formData?.employerAddressLine2}
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Apartment Number</label>
              <input
                type="text"
                class="form-control"
                id="employerApartmentNumber"
                placeholder="Apartment Number"
                onChange={(e) => [updateFormData(e), setError()]}
                value={formData?.employerApartmentNumber}
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">City *</label>
              <input
                type="text"
                class="form-control"
                id="employerCity"
                placeholder="City"
                onChange={(e) => [updateFormData(e), setError()]}
                value={formData?.employerCity}
              />
              <small class="form-text " style={{ color: "red" }}>
                {error?.field === "employerCity" && error.message}
              </small>
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">
                State / Province / Region *
              </label>
              <input
                type="text"
                class="form-control"
                id="employerState"
                placeholder="State / Province / Region"
                onChange={(e) => [updateFormData(e), setError()]}
                value={formData?.employerState}
              />
              <small class="form-text " style={{ color: "red" }}>
                {error?.field === "employerState" && error.message}
              </small>
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Country *</label>
              <CountryDropdown
                id="employerCountry"
                onChange={(e) => [updateFormData(e), setError()]}
                value={formData?.employerCountry}
              />
              <small class="form-text " style={{ color: "red" }}>
                {error?.field === "employerCountry" && error.message}
              </small>
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Country Code</label>
              <CountryCode
                id="employerCountryCode"
                changed={(e) => [setErrorNested(), updateFormData(e)]}
                value={formData?.employerCountryCode}
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Telephone Number</label>
              <input
                type="text"
                class="form-control"
                id="employerTelephoneNumber"
                placeholder="Telephone Number"
                onChange={(e) => [updateFormData(e), setError()]}
                value={formData?.employerTelephoneNumber}
              />
            </div>
          </div>
        )}
      </div>
      <div class="modal-footer">
        <button
          onClick={() => (window.location.href = "/applicantinfo")}
          type="button"
          class="btn btn-primary"
        >
          {"<"} Previous Page
        </button>
        <button onClick={() => next()} type="button" class="btn btn-primary">
          Next Page {">"}
        </button>
      </div>
    </form>
  );
}
export default PersonalInformation;
