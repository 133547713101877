import React, { useState } from "react";

function FAQComponent() {
  const [accordionArray, setAccordionArray] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  function selected(value) {
    var copy = accordionArray;

    copy[value] = !copy[value];

    setAccordionArray([...copy]);
  }

  return (
    <section class="faq-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="col-lg-6 col-md-6 col-sm-12 faq-column">
            <ul class="accordion-box">
              <li
                class={
                  "accordion block" +
                  (accordionArray && accordionArray[0] === true
                    ? " active-block"
                    : "")
                }
              >
                <div class="acc-btn " onClick={() => selected(0)}>
                  <div class="icon-outer">
                    <i class="fas fa-angle-down"></i>
                  </div>
                  <h5>DO I NEED AN ESTA TO VISIT THE USA FROM THE UK?</h5>
                </div>
                <div
                  class={
                    "acc-content" +
                    (accordionArray && accordionArray[0] === true
                      ? " current"
                      : "")
                  }
                >
                  <p>
                    Yes, if you are intending to visit the United States for a
                    maximum of 90 days, hold a valid e-passport from a Visa
                    Waiver Programme country and are visiting for either
                    business, pleasure or transit with a valid departure or
                    return ticket. If you do not meet these requirements you
                    will then need to apply for a full visa.
                  </p>
                </div>
              </li>
              <li
                class={
                  "accordion block" +
                  (accordionArray && accordionArray[1] === true
                    ? " active-block"
                    : "")
                }
                onClick={() => selected(1)}
              >
                <div class="acc-btn">
                  <div class="icon-outer">
                    <i class="fas fa-angle-down"></i>
                  </div>
                  <h5>HOW FAST WILL MY ESTA APPLICATION TAKE?</h5>
                </div>
                <div
                  class={
                    "acc-content" +
                    (accordionArray && accordionArray[1] === true
                      ? " current"
                      : "")
                  }
                >
                  <p>
                    We aim to complete all applications within the same working
                    day. In most cases the average application is returned
                    within a few hours, however in cases where more time is
                    needed to process an electronic travel authorisation
                    application, an answer will be usually returned within 72
                    hours. Information will be provided to check the application
                    status if we receive a pending response.
                  </p>
                </div>
              </li>
              <li
                class={
                  "accordion block" +
                  (accordionArray && accordionArray[2] === true
                    ? " active-block"
                    : "")
                }
                onClick={() => selected(2)}
              >
                <div class="acc-btn">
                  <div class="icon-outer">
                    <i class="fas fa-angle-down"></i>
                  </div>
                  <h5>HOW MUCH DOES MY APPLICATION COST?</h5>
                </div>
                <div
                  class={
                    "acc-content" +
                    (accordionArray && accordionArray[2] === true
                      ? " current"
                      : "")
                  }
                >
                  <p>
                    Each ESTA application service costs $79 including VAT and
                    includes $14 US Government’s fee.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 faq-column">
            <ul class="accordion-box">
              <li
                class={
                  "accordion block" +
                  (accordionArray && accordionArray[3] === true
                    ? " active-block"
                    : "")
                }
                onClick={() => selected(3)}
              >
                <div class="acc-btn">
                  <div class="icon-outer">
                    <i class="fas fa-angle-down"></i>
                  </div>
                  <h5>HOW EARLY SHOULD I APPLY FOR AN ESTA?</h5>
                </div>
                <div
                  class={
                    "acc-content" +
                    (accordionArray && accordionArray[3] === true
                      ? " current"
                      : "")
                  }
                >
                  <p>
                    For ease of mind The United States Department of Homeland
                    Security recommends that you apply for an ESTA 72 hours
                    before you board your plane or ship. In most circumstances
                    applications are answered within a few hours however.
                  </p>
                </div>
              </li>
              <li
                class={
                  "accordion block" +
                  (accordionArray && accordionArray[4] === true
                    ? " active-block"
                    : "")
                }
                onClick={() => selected(4)}
              >
                <div class="acc-btn">
                  <div class="icon-outer">
                    <i class="fas fa-angle-down"></i>
                  </div>
                  <h5>HOW LONG DOES IS AN ESTA VALID FOR?</h5>
                </div>
                <div
                  class={
                    "acc-content" +
                    (accordionArray && accordionArray[4] === true
                      ? " current"
                      : "")
                  }
                >
                  <p>
                    An ESTA is valid for 2 years from the date of issue, however
                    a new ESTA will be required if the following change:
                    <br />
                    <br />
                    You receive a new passport
                    <br />
                    You change your name
                    <br />
                    You change gender
                    <br />
                    You change your citizenship to another country
                    <br />
                  </p>
                </div>
              </li>
              <li
                class={
                  "accordion block" +
                  (accordionArray && accordionArray[5] === true
                    ? " active-block"
                    : "")
                }
                onClick={() => selected(5)}
              >
                <div class="acc-btn">
                  <div class="icon-outer">
                    <i class="fas fa-angle-down"></i>
                  </div>
                  <h5>WHAT IF MY ESTA APPLICATION IS REJECTED?</h5>
                </div>
                <div
                  class={
                    "acc-content" +
                    (accordionArray && accordionArray[5] === true
                      ? " current"
                      : "")
                  }
                >
                  <p>
                    If your ESTA application is rejected then you will need to
                    contact the US Embassy and request a regular visa.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQComponent;
