import React from "react";

function Offers() {
  return (
    <section class="offer-section" style={{ paddingBottom: "0px" }}>
      <div
        class="pattern-layer"
        style={{
          backgroundImage: "url(assets/images/shape/pattern-5.png)",
          height: "680px",
        }}
      ></div>
      <div class="auto-container pb-5">
        <div class="sec-title light centred">
          <p>countries we offer support</p>
          <h2>
            AN ESTA IS ONLY AVAILABLE FOR PASSPORT HOLDERS OF THE FOLLOWING
            COUNTRIES:
          </h2>
          <div class="dotted-box">
            <span class="dotted"></span>
            <span class="dotted"></span>
            <span class="dotted"></span>
          </div>
        </div>
        <div
          class="container-fluid"
          style={{
            color: "white",
            textAlign: "left",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          <div className="row">
            <div className="col-3">
              ANDORRA <br />
              AUSTRALIA <br />
              AUSTRIA <br />
              BELGIUM <br />
              BRUNEI <br />
              CHILE <br />
              CZECH REPUBLIC <br />
              DENMARK <br />
              GERMANY <br />
              ESTONIA
            </div>
            <div className="col-3">
              FINLAND
              <br />
              FRANCE
              <br />
              GREECE
              <br />
              HUNGARY
              <br />
              ICELAND
              <br />
              IRELAND
              <br />
              ITALY
              <br />
              JAPAN
              <br />
              LATVIA
              <br />
              LIECHTENSTEIN
              <br />
            </div>
            <div className="col-3">
              LITHUANIA
              <br />
              LUXEMBOURG
              <br />
              MALTA
              <br />
              MONACO
              <br />
              NETHERLANDS
              <br />
              NEW ZEALAND
              <br />
              NORWAY
              <br />
              PORTUGAL
              <br />
              SAN MARINO
              <br />
            </div>
            <div className="col-3">
              SINGAPORE
              <br />
              SLOVAKIA
              <br />
              SLOVENIA
              <br />
              SOUTH KOREA
              <br />
              SPAIN
              <br />
              SWEDEN
              <br />
              SWITZERLAND
              <br />
              TAIWAN
              <br />
              UNITED KINGDOM
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Offers;
