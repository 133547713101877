import React from "react";

function NotFound() {
  return (
    <>
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title.jpg)",
        }}
      >
        <div class="auto-container">
          <div class="content-box">
            <div class="title-box">
              <h1>Error Page</h1>
              <div class="dotted-box">
                <span class="dotted"></span>
                <span class="dotted"></span>
                <span class="dotted"></span>
              </div>
            </div>
            <ul class="bread-crumb clearfix">
              <li>
                <i class="flaticon-home-1"></i>
                <a href="index.html">Home</a>
              </li>
              <li>404</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="error-section centred">
        <div class="container">
          <div class="content-box">
            <h1>404</h1>
            <h2>Oops, This Page Not Be Found !</h2>
            <div class="text">
              Can't find what you need? Take a moment and do a search
              <br /> below or start from our <a href="/">Homepage.</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NotFound;
