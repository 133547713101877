import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { formAtom } from "../Atoms";
import { useRecoilState } from "recoil";

const options = {
  loop: true,
  margin: 0,
  nav: true,
  animateOut: "fadeOut",
  animateIn: "fadeIn",
  active: true,
  smartSpeed: 1000,
  autoplay: 6000,
  autoHeight: false,

  navText: [
    '<span class="fa fa-angle-left"></span>',
    '<span class="fa fa-angle-right"></span>',
  ],
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    800: {
      items: 1,
    },
    1024: {
      items: 1,
    },
  },
};

function Banner() {
  const [showForm, setShowForm] = useRecoilState(formAtom);

  function showFormFunction() {
    var copy = showForm;
    console.log(copy);
    copy.visible = true;
    console.log(copy);
    setShowForm({ ...copy });
  }

  return (
    <section class="banner-section style-one">
      <OwlCarousel
        className="banner-carousel owl-theme owl-carousel owl-dots-none"
        {...options}
      >
        <div
          class="slide-item image-layer"
          style={{
            backgroundImage: "url('/assets/images/banner/banner-1.jpeg')",
          }}
        >
          <div class="auto-container">
            <div class="row clearfix">
              <div
                class="col-xl-7 col-lg-12 col-md-12 content-column"
                style={{
                  display: "flex",
                  alignContent: "center",
                  flexDirection: "col",
                }}
              >
                <div class="content-box">
                  <div class="title-text">
                    <h1>Apply for an ESTA visitor visa for the U.S.</h1>
                    <div class="dotted-box">
                      <span class="dotted"></span>
                      <span class="dotted"></span>
                      <span class="dotted"></span>
                    </div>
                  </div>
                  <p>
                    4 Minute Applicaiton - ЕТSA АррІісаtion Services to Travel
                    U.S. under the Permit Waiver Program.
                  </p>
                  <div class="btn-box ">
                    <a href="/apply" class="theme-btn-one">
                      <i class="flaticon-send"></i>Start Application $99
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
}
export default Banner;
