import React from "react";

function Privacy() {
  return (
    <>
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title.jpg)",
        }}
      >
        <div class="auto-container">
          <div class="content-box">
            <div class="title-box">
              <h1>Privacy Policy</h1>
              <div class="dotted-box">
                <span class="dotted"></span>
                <span class="dotted"></span>
                <span class="dotted"></span>
              </div>
            </div>
            <ul class="bread-crumb clearfix">
              <li>
                <i class="flaticon-home-1"></i>
                <a href="index.html">Home</a>
              </li>
              <li>Privacy Policy</li>
            </ul>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-5 pb-5">
          <div class="wpb_wrapper">
            <div class="wpb_text_column wpb_content_element  vc_custom_1527586155787">
              <div class="wpb_wrapper">
                <h1 class="sc_title sc_title_regular">Privacy Policy</h1>
              </div>
            </div>
            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <div class="wpb_wrapper">
                  <p>
                    Services offered through Trusty Visas are governed by our
                    Privacy Policy. If you have questions pertaining to our
                    Privacy Policy, please contact us by email. We will respond
                    within 48 hours.
                  </p>
                  <p>
                    Your information is protected with the highest protection
                    available through physical security, firewalls, encryption,
                    and passwords.
                  </p>
                  <p>
                    We do not collect your credit card number when you purchase
                    one of our products. Only the bank which administrates our
                    merchant account has access to your full credit card number.
                  </p>
                  <p>
                    Every registered user has the right to delete their data. If
                    you want to delete your data from our database, please
                    contact us at info@trustyvisas-esta.com.
                  </p>
                  <p>
                    We reserve the right to refuse service to any applicant for
                    any reason, including but not limited to misrepresented or
                    fraudulent transactions which will be canceled without any
                    further notice.
                  </p>
                  <p>
                    This is the privacy notice of Trusty Visas In this document,
                    “we”, “our”, or “us” refer to Trusty Visas.
                  </p>
                  <p>&nbsp;</p>
                  <p>Introduction</p>
                  <ol>
                    <li>
                      This is a notice to inform you of our policy about all
                      information that we record about you. It sets out the
                      conditions under which we may process any information that
                      we collect from you, or that you provide to us. It covers
                      information that could identify you (“personal
                      information”) and information that could not. In the
                      context of the law and this notice, “process” means
                      collect, store, transfer, use or otherwise act on
                      information.
                    </li>
                    <li>
                      We regret that if there are one or more points below with
                      which you are not happy, your only recourse is to leave
                      our website immediately.
                    </li>
                    <li>
                      We take seriously the protection of your privacy and
                      confidentiality. We understand that all visitors to our
                      website are entitled to know that their personal data will
                      not be used for any purpose unintended by them, and will
                      not accidentally fall into the hands of a third party.
                    </li>
                    <li>
                      We undertake to preserve the confidentiality of all
                      information you provide to us, and hope that you
                      reciprocate.
                    </li>
                    <li>
                      We do not store credit card details nor do we share
                      customer details with any 3rd parties.
                    </li>
                    <li>
                      Our policy complies with UK law accordingly implemented,
                      including that required by the EU General Data Protection
                      Regulation (GDPR).
                    </li>
                    <li>
                      The law requires us to tell you about your rights and our
                      obligations to you in regards to the processing and
                      control of your personal data. We do this now, by
                      requesting that you read the information provided at&nbsp;
                      <a href="http://www.knowyourprivacyrights.org/">
                        knowyourprivacyrights.org
                      </a>
                    </li>
                    <li>
                      Except as set out below, we do not share, or sell, or
                      disclose to a third party, any information collected
                      through our website.
                    </li>
                  </ol>
                  <p>The bases on which we process information about you</p>
                  <p>
                    The law requires us to determine under which of six defined
                    bases we process different categories of your personal
                    information, and to notify you of the basis for each
                    category.
                  </p>
                  <p>
                    If a basis on which we process your personal information is
                    no longer relevant then we shall immediately stop processing
                    your data.
                  </p>
                  <p>
                    If the basis changes then if required by law we shall notify
                    you of the change and of any new basis under which we have
                    determined that we can continue to process your information.
                  </p>
                  <ol>
                    <li>
                      <strong>
                        Information we process because we have a contractual
                        obligation with you
                      </strong>
                    </li>
                  </ol>
                  <p>
                    When you create an account on our website, buy a product or
                    service from us, or otherwise agree to our terms and
                    conditions, a contract is formed between you and us.
                  </p>
                  <p>
                    In order to carry out our obligations under that contract we
                    must process the information you give us. Some of this
                    information may be personal information.
                  </p>
                  <p>We may use it in order to:</p>
                  <p>
                    <strong>1.1</strong>&nbsp;&nbsp; verify your identity for
                    security purposes
                  </p>
                  <p>
                    <strong>1.2</strong>&nbsp;&nbsp; sell products to you
                  </p>
                  <p>
                    <strong>1.3</strong>&nbsp;&nbsp; provide you with our
                    services
                  </p>
                  <p>
                    <strong>1.4</strong>&nbsp;&nbsp; provide you with
                    suggestions and advice on products, services and how to
                    obtain the most from using our website
                  </p>
                  <p>
                    We process this information on the basis there is a contract
                    between us, or that you have requested we use the
                    information before we enter into a legal contract.
                  </p>
                  <p>
                    Additionally, we may aggregate this information in a general
                    way and use it to provide class information, for example to
                    monitor our performance with respect to a particular service
                    we provide. If we use it for this purpose, you as an
                    individual will not be personally identifiable.
                  </p>
                  <p>
                    We shall continue to process this information until the
                    contract between us ends or is terminated by either party
                    under the terms of the contract.
                  </p>
                  <ol start="2">
                    <li>
                      <strong>Information we process with your consent</strong>
                    </li>
                  </ol>
                  <p>
                    Through certain actions when otherwise there is no
                    contractual relationship between us, such as when you browse
                    our website or ask us to provide you more information about
                    our products and services, you provide your consent to us to
                    process information that may be personal information.
                  </p>
                  <p>
                    Wherever possible, we aim to obtain your explicit consent to
                    process this information, for example, by asking you to
                    agree to our use of cookies.
                  </p>
                  <p>
                    Sometimes you might give your consent implicitly, such as
                    when you send us a message by e-mail to which you would
                    reasonably expect us to reply.
                  </p>
                  <p>
                    Except where you have consented to our use of your
                    information for a specific purpose, we do not use your
                    information in any way that would identify you personally.
                    We may aggregate it in a general way and use it to provide
                    class information, for example to monitor the performance of
                    a particular page on our website.
                  </p>
                  <p>
                    If you have given us explicit permission to do so, we may
                    from time to time pass your name and contact information to
                    selected associates whom we consider may provide services or
                    products you would find useful.
                  </p>
                  <p>
                    We continue to process your information on this basis until
                    you withdraw your consent or it can be reasonably assumed
                    that your consent no longer exists.
                  </p>
                  <p>
                    You may withdraw your consent at any time by instructing
                    us&nbsp;info@trustyvisas-esta.com. However, if you do so,
                    you may not be able to use our website or our services
                    further.
                  </p>
                  <ol start="3">
                    <li>
                      <strong>
                        Information we process because we have a legal
                        obligation
                      </strong>
                    </li>
                  </ol>
                  <p>
                    We are subject to the law like everyone else. Sometimes, we
                    must process your information in order to comply with a
                    statutory obligation.
                  </p>
                  <p>
                    For example, we may be required to give information to legal
                    authorities if they so request or if they have the proper
                    authorisation such as a search warrant or court order.
                  </p>
                  <p>This may include your personal information.</p>
                  <p>Specific uses of information you provide to us</p>
                  <ol start="4">
                    <li>
                      <strong>Reviews you post on our website</strong>
                    </li>
                  </ol>
                  <p>
                    Our website allows you to post reviews about the product or
                    service you have purchased with a view to that information
                    being read or used by other people.
                  </p>
                  <p>
                    We do not specifically use this information except to allow
                    it to be displayed on our website.
                  </p>
                  <p>
                    We do store it, and we reserve a right to use it in the
                    future in any way we decide.
                  </p>
                  <p>
                    Provided your request is reasonable and there is no legal
                    basis for us to retain it, then at our discretion we may
                    agree to your request to delete your review with your
                    personal information that you have posted. You can make a
                    request by contacting us at info@trustyvisas-esta.com
                  </p>
                  <ol start="5">
                    <li>
                      <strong>
                        Information relating to your method of payment
                      </strong>
                    </li>
                  </ol>
                  <p>
                    Payment information is never taken by us or transferred to
                    us either through our website or otherwise. Our employees
                    and contractors never have access to it.
                  </p>
                  <p>
                    At the point of payment, you are transferred to a secure
                    page on the website of SagePay and PayPal. That page may be
                    branded to look like a page on our website, but it is not
                    controlled by us.
                  </p>
                  <ol start="6">
                    <li>
                      <strong>Sending a message to our support team</strong>
                    </li>
                  </ol>
                  <p>
                    When you contact us through our website or by e-mail, we
                    collect the data you have given to us in order to reply with
                    the information you need.
                  </p>
                  <p>
                    We record your request and our reply in order to increase
                    the efficiency of our business.
                  </p>
                  <p>
                    We keep personally identifiable information associated with
                    your message, such as your name and email address so as to
                    be able to track our communications with you to provide a
                    high quality service.
                  </p>
                  <ol start="7">
                    <li>
                      <strong>Complaining</strong>
                    </li>
                  </ol>
                  <p>
                    When we receive a complaint, we record all the information
                    you have given to us.
                  </p>
                  <p>We use that information to resolve your complaint.</p>
                  <p>
                    If your complaint reasonably requires us to contact some
                    other person, we may decide to give to that other person
                    some of the information contained in your complaint. We do
                    this as infrequently as possible, but it is a matter for our
                    sole discretion as to whether we do give information, and if
                    we do, what that information is.
                  </p>
                  <p>
                    We may also compile statistics showing information obtained
                    from this source to assess the level of service we provide,
                    but not in a way that could identify you or any other
                    person.
                  </p>
                  <ol start="8">
                    <li>
                      <strong>
                        Affiliate and business partner information
                      </strong>
                    </li>
                  </ol>
                  <p>
                    This is information given to us by you in your capacity as
                    an affiliate of us or as a business partner.
                  </p>
                  <p>
                    It allows us to recognise visitors that you have referred to
                    us, and to credit to you commission due for such referrals.
                    It also includes information that allows us to transfer
                    commission to you.
                  </p>
                  <p>The information is not used for any other purpose.</p>
                  <p>
                    We undertake to preserve the confidentiality of the
                    information and of the terms of our relationship.
                  </p>
                  <p>
                    We expect any affiliate or partner to agree to reciprocate
                    this policy.
                  </p>
                  <p>
                    Use of information we collect through automated systems when
                    you visit our website
                  </p>
                  <ol start="9">
                    <li>
                      <strong>Cookies</strong>
                    </li>
                  </ol>
                  <p>
                    Cookies are small text files that are placed on your
                    computer’s hard drive by your web browser when you visit a
                    website. They allow information gathered on one web page to
                    be stored until it is needed for use on another, allowing a
                    website to provide you with a personalised experience, and
                    the website owner with statistics about how you use the
                    website so that it can be improved.
                  </p>
                  <p>
                    Some cookies may last for a defined period of time, such as
                    one day or until you close your browser. Others last
                    indefinitely.
                  </p>
                  <p>
                    Our website uses third party cookies. They are placed by
                    software that operates on our servers, and by software
                    operated by third parties whose services we use.
                  </p>
                  <p>
                    An example is that we use Google Analytics to measure the
                    performance of our website.
                  </p>
                  <p>
                    We may use the information we obtain from your use of our
                    cookies for the following purposes:
                  </p>
                  <p>to improve the website’s usability;</p>
                  <p>to analyse your use of our website;</p>
                  <p>
                    to provide certain interactive functionality, such as
                    remembering your answers to previous questions on a
                    multi-page questionnaire.
                  </p>
                  <p>
                    Most of browsers allow you to refuse to accept cookies, and
                    to delete ones that have been placed on your computer.
                  </p>
                  <p>
                    If you do not want us to use cookies, you may disable their
                    use in your browser. However, if you do so then please note
                    that you may not be able to use all the features on our
                    website.
                  </p>
                  <p>Disclosure and sharing of your information</p>
                  <ol start="10">
                    <li>
                      <strong>Information we obtain from third parties</strong>
                    </li>
                  </ol>
                  <p>
                    Although we do not disclose your personal information to any
                    third party (except as set out in this notice), we sometimes
                    receive data that is indirectly made up from your personal
                    information from third parties whose services we use.
                  </p>
                  <p>No such information is personally identifiable to you.</p>
                  <ol start="11">
                    <li>
                      <strong>Credit reference</strong>
                    </li>
                  </ol>
                  <p>
                    To assist in combating fraud, we share information with
                    credit reference agencies, so far as it relates to clients
                    or customers who instruct their credit card issuer to cancel
                    payment to us without having first provided an acceptable
                    reason to us and given us the opportunity to refund their
                    money.
                  </p>
                  <ol start="12">
                    <li>
                      <strong>
                        Data may be processed outside the European Union
                      </strong>
                    </li>
                  </ol>
                  <p>Our websites are hosted in UK</p>
                  <p>
                    We may also use outsourced services in countries outside the
                    European Union from time to time in other aspects of our
                    business.
                  </p>
                  <p>
                    Accordingly data obtained within the UK or any other country
                    could be processed outside the European Union.
                  </p>
                  <p>Access to your own information</p>
                  <ol start="13">
                    <li>
                      <strong>Access to your personal information</strong>
                    </li>
                  </ol>
                  <p>
                    <strong>13.1</strong>&nbsp;&nbsp; At any time you may review
                    or update personally identifiable information that we hold
                    about you, by signing in to your account on our website.
                  </p>
                  <p>
                    <strong>13.2</strong>&nbsp;&nbsp; To obtain a copy of any
                    information that is not provided on our website you may send
                    us a request at info@trustyvisas-esta.com
                  </p>
                  <p>
                    <strong>13.3</strong>&nbsp;&nbsp; After receiving the
                    request, we will tell you when we expect to provide you with
                    the information, and whether we require any fee for
                    providing it to you.
                  </p>
                  <ol start="14">
                    <li>
                      <strong>Removal of your information</strong>
                    </li>
                  </ol>
                  <p>
                    If you wish us to remove personally identifiable information
                    from our website, you may contact us
                    at&nbsp;info@trustyvisas-esta.com
                  </p>
                  <p>This may limit the service we can provide to you.</p>
                  <ol start="15">
                    <li>
                      <strong>Verification of your information</strong>
                    </li>
                  </ol>
                  <p>
                    When we receive any request to access, edit or delete
                    personal identifiable information we shall first take
                    reasonable steps to verify your identity before granting you
                    access or otherwise taking any action. This is important to
                    safeguard your information.
                  </p>
                  <p>Other matters</p>
                  <ol start="16">
                    <li>
                      <strong>Use of site by children</strong>
                    </li>
                  </ol>
                  <p>
                    <strong>16.1</strong>&nbsp;&nbsp; We do not sell products or
                    provide services for purchase by children, nor do we market
                    to children.
                  </p>
                  <p>
                    <strong>16.2</strong>&nbsp;&nbsp; If you are under 18, you
                    may use our website only with consent from a parent or
                    guardian
                  </p>
                  <ol start="17">
                    <li>
                      <strong>How you can complain</strong>
                    </li>
                  </ol>
                  <p>
                    <strong>17.1</strong>&nbsp;&nbsp; If you are not happy with
                    our privacy policy or if you have any complaint then you
                    should tell us by email. Our address
                    is&nbsp;info@trustyvisas-esta.com
                  </p>
                  <p>
                    <strong>17.2</strong>&nbsp;&nbsp; If a dispute is not
                    settled then we hope you will agree to attempt to resolve it
                    by engaging in good faith with us in a process of mediation
                    or arbitration.
                  </p>
                  <p>
                    <strong>17.3</strong>&nbsp;&nbsp; If you are in any way
                    dissatisfied about how we process your personal information,
                    you have a right to lodge a complaint with the Information
                    Commissioner’s Office. This can be done at&nbsp;
                    <a href="https://ico.org.uk/concerns/">
                      https://ico.org.uk/concerns/
                    </a>
                  </p>
                  <ol start="18">
                    <li>
                      <strong>Retention period for personal data</strong>
                    </li>
                  </ol>
                  <p>
                    Except as otherwise mentioned in this privacy notice, we
                    keep your personal information only for as long as required
                    by us:
                  </p>
                  <p>
                    <strong>18.1</strong>&nbsp;&nbsp; to provide you with the
                    services you have requested;
                  </p>
                  <p>
                    <strong>18.2</strong>&nbsp;&nbsp; to comply with other law,
                    including for the period demanded by our tax authorities;
                  </p>
                  <p>
                    <strong>18.3</strong>&nbsp;&nbsp; to support a claim or
                    defence in court.
                  </p>
                  <ol start="19">
                    <li>
                      <strong>Compliance with the law</strong>
                    </li>
                  </ol>
                  <p>
                    Our privacy policy has been compiled so as to comply with
                    the law of every country or legal jurisdiction in which we
                    aim to do business. If you think it fails to satisfy the law
                    of your jurisdiction, we should like to hear from you.
                  </p>
                  <p>
                    However, ultimately it is your choice as to whether you wish
                    to use our website.
                  </p>
                  <ol start="20">
                    <li>
                      <strong>Review of this privacy policy</strong>
                    </li>
                  </ol>
                  <p>
                    We may update this privacy notice from time to time as
                    necessary. The terms that apply to you are those posted here
                    on our website on the day you use our website. We advise you
                    to print a copy for your records.
                  </p>
                  <p>
                    If you have any question regarding our privacy policy,
                    please contact us on info@trustyvisas-esta.com
                  </p>
                  <p>
                    Any questions concerning www.trustyvisas-esta.com and its
                    Privacy Policy should be addressed to
                    info@trustyvisas-esta.com.
                  </p>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </section>
    </>
  );
}
export default Privacy;
