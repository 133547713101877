import React from "react";
import FAQComponent from "../Components/FAQComponent";

function FAQ() {
  return (
    <>
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-4.jpg)",
        }}
      >
        <div class="auto-container">
          <div class="content-box">
            <div class="title-box">
              <h1>Read Our FAQ’s</h1>
              <div class="dotted-box">
                <span class="dotted"></span>
                <span class="dotted"></span>
                <span class="dotted"></span>
              </div>
            </div>
            <ul class="bread-crumb clearfix">
              <li>
                <i class="flaticon-home-1"></i>
                <a href="index.html">Home</a>
              </li>
              <li>FAQ’s</li>
            </ul>
          </div>
        </div>
      </section>

      <section>
        <FAQComponent />
      </section>
    </>
  );
}

export default FAQ;
