import React, { useEffect, useState } from "react";
import PaymentPage from "../Components/Forms/PaymentPage";
import ApplicantInformation from "../Components/Forms/ApplicantInformation";
import PersonalInformation from "../Components/Forms/PersonalInformation";
import ElegibilityQuestions from "../Components/Forms/ElegibilityQuestions";
import TravelInformation from "../Components/Forms/TravelInformation";
import { useRef } from "react";
import Disclaimers from "../Components/Forms/Disclaimers";
import Review from "../Components/Forms/Review";

function Apply(props) {
  const [page, setPage] = useState();
  const elementRef = useRef();

  useEffect(() => {
    if (props.page) {
      setPage(props.page);
    } else {
      setPage(1);
    }
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    console.log(id);
    if (id != null) {
      setPage(9);
    }
  }, []);

  function setPageFunc(pageNumber) {
    window.scrollTo(0, elementRef.current.offsetTop - 100);
    setPage(pageNumber);
  }

  return (
    <>
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title.jpg)",
        }}
      >
        <div class="auto-container">
          <div class="content-box">
            <div class="title-box">
              <h1>Esta Application Form</h1>
              <div class="dotted-box">
                <span class="dotted"></span>
                <span class="dotted"></span>
                <span class="dotted"></span>
              </div>
            </div>
            <ul class="bread-crumb clearfix">
              <li>
                <i class="flaticon-home-1"></i>
                <a href="index.html">Home</a>
              </li>
              <li>Apply Process</li>
            </ul>
          </div>
        </div>
      </section>
      <div _ngcontent-rwn-c34="" class="steps d-none d-md-block">
        <div _ngcontent-rwn-c34="" class="container">
          <ol _ngcontent-rwn-c34="" class="nav-steps">
            <li
              _ngcontent-rwn-c34=""
              style={{ width: "14.3%" }}
              class={page === 1 && "active"}
            >
              <a _ngcontent-rwn-c34="">
                <span _ngcontent-rwn-c34="">Disclaimers {page}</span>
              </a>
            </li>
            <li
              _ngcontent-rwn-c34=""
              style={{ width: "14.3%" }}
              class={page === 2 && "active"}
            >
              <a _ngcontent-rwn-c34="">
                <span _ngcontent-rwn-c34="">Applicant Information</span>
              </a>
            </li>
            <li
              _ngcontent-rwn-c34=""
              style={{ width: "14.3%" }}
              class={page === 3 && "active"}
            >
              <a _ngcontent-rwn-c34="">
                <span _ngcontent-rwn-c34="">Personal Information</span>
              </a>
            </li>
            <li
              _ngcontent-rwn-c34=""
              style={{ width: "14.3%" }}
              class={page === 4 && "active"}
            >
              <a _ngcontent-rwn-c34="">
                <span _ngcontent-rwn-c34="">Travel Information</span>
              </a>
            </li>
            <li
              _ngcontent-rwn-c34=""
              style={{ width: "14.3%" }}
              class={page === 5 && "active"}
            >
              <a _ngcontent-rwn-c34="">
                <span _ngcontent-rwn-c34="">Eligibility Questions</span>
              </a>
            </li>
            <li
              _ngcontent-rwn-c34=""
              style={{ width: "14.3%" }}
              class={page === 6 && "active"}
            >
              <a _ngcontent-rwn-c34="">
                <span _ngcontent-rwn-c34="">Review Application</span>
              </a>
            </li>
            <li
              _ngcontent-rwn-c34=""
              style={{ width: "14.3%" }}
              class={page === 7 && "active"}
            >
              <a _ngcontent-rwn-c34="">
                <span _ngcontent-rwn-c34="">Pay</span>
              </a>
            </li>
          </ol>
        </div>
      </div>
      <div className="container pt-5 pb-5" ref={elementRef}>
        {/*page === 1 && <PaymentPage previousPage={() => setPage(1)} />*/}
        {page === 1 && <Disclaimers nextPage={() => setPageFunc(2)} />}
        {page === 2 && (
          <ApplicantInformation
            nextPage={() => setPageFunc(3)}
            previousPage={() => setPageFunc(1)}
          />
        )}
        {page === 3 && (
          <PersonalInformation
            nextPage={() => setPageFunc(4)}
            previousPage={() => setPageFunc(2)}
          />
        )}
        {page === 4 && (
          <TravelInformation
            nextPage={() => setPageFunc(5)}
            previousPage={() => setPageFunc(3)}
          />
        )}
        {page === 5 && (
          <ElegibilityQuestions
            nextPage={() => setPageFunc(6)}
            previousPage={() => setPageFunc(4)}
          />
        )}
        {page === 6 && (
          <Review
            nextPage={() => setPageFunc(7)}
            previousPage={() => setPageFunc(5)}
          />
        )}

        {page === 7 && <PaymentPage previousPage={() => setPageFunc(6)} />}
      </div>
    </>
  );
}
export default Apply;
