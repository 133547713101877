import React from "react";

function Refund() {
  return (
    <>
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title.jpg)",
        }}
      >
        <div class="auto-container">
          <div class="content-box">
            <div class="title-box">
              <h1>Refund Policy</h1>
              <div class="dotted-box">
                <span class="dotted"></span>
                <span class="dotted"></span>
                <span class="dotted"></span>
              </div>
            </div>
            <ul class="bread-crumb clearfix">
              <li>
                <i class="flaticon-home-1"></i>
                <a href="index.html">Home</a>
              </li>
              <li>Refund Policy</li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container pt-5 pb-5">
        <div class="wpb_wrapper">
          <div class="wpb_text_column wpb_content_element ">
            <div class="wpb_wrapper">
              <h1 class="sc_title sc_title_regular">Refund Policy</h1>
              <div class="sc_content main">
                <div class="wpb_text_column wpb_content_element ">
                  <div class="wpb_wrapper">
                    <p>
                      A full refund will be issued to all users who have made
                      their application through Trusty Visas in case of a
                      rejected application by the US Government.
                    </p>
                    <p>
                      Once you have submitted your application through Trusty
                      Visas, it is assumed and agreed that we will begin the
                      submission process within the timeframe indicated during
                      your application which in most cases is 24 hours.
                    </p>
                    <p>
                      Should you decide to request a refund after your
                      application has been submitted, you can apply to be
                      considered for receiving a refund of agency application
                      service fees.
                    </p>
                    <p>
                      Refunds will not be issued after an application has been
                      approved by the US Government. As we are a private
                      company, unaffiliated with government agencies, we
                      emphasise that decisions on admission into the United
                      States are at the sole discretion of the Customs and
                      Border Protection Office.
                    </p>
                    <p>
                      If a refund is requested after the Trusty Visas
                      application is processed, for any reason other than
                      application refusal, a fee may apply if the application
                      has already been processed.
                    </p>
                    <p>
                      Our refund covers the fees paid to us directly for
                      processing your application and unfortunately does not
                      cover any third-party fees you may incur, such as
                      credit/debit card fees.
                    </p>
                    <p>
                      If you wish to request a refund, email us at
                      support@trustyvisas.com indicating the following:
                    </p>
                    <ul>
                      <li>Reason for the refund request.</li>
                      <li>Your full name</li>
                      <li>Your unique transaction ID.</li>
                      <li>Your email address</li>
                    </ul>
                    <p>
                      For multiple requests, please indicate all transaction
                      IDs.
                      <br />
                      All refund requests will be evaluated within 72 hours.
                    </p>
                  </div>
                </div>
                <div class="wpb_text_column wpb_content_element ">
                  <div class="wpb_wrapper">
                    <p>
                      Please Check Our&nbsp;
                      <a href="/privacy">
                        <strong>Privacy Policy Here.</strong>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
}
export default Refund;
