import React from "react";

function Training() {
  return (
    <section class="training-section bg-color-2">
      <div
        class="pattern-layer"
        style={{ backgroundImage: "url(assets/images/shape/pattern-2.png)" }}
      ></div>
      <div class="auto-container">
        <div class="top-inner">
          <div class="row clearfix">
            <div class="col-12 title-column">
              <div class="sec-title light">
                <p>Information</p>
                <h2>Important</h2>
                <div class="dotted-box">
                  <span class="dotted"></span>
                  <span class="dotted"></span>
                  <span class="dotted"></span>
                </div>
              </div>
            </div>
            <div class="col-12 text-column">
              <div class="text">
                <p>
                  We are not affiliated with the United States government. Our
                  service will help you with value added services to acquire
                  your ESTA. For each ESTA application, you pay a service fee of
                  $79 USD which includes the official government processing fee
                  of $14 USD. Our experienced experts can make the difference
                  between a successful and an unsuccessful self-application. You
                  can save your time and get better results with us and we
                  supply email updates of the application process.
                  Alternatively, you can apply directly for ESTA through the
                  United States government website www.esta.cbp.dhs.gov. Once
                  your application is submitted to us we will begin the official
                  application process. If we have any questions or concerns
                  prior to processing your application we will contact you. We
                  offer no guarantee of ESTA acceptance and we are unable to
                  offer any refunds once your application is submitted.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Training;
