import React, { useEffect, useState } from "react";
import { formAtom } from "../../Atoms";
import { useRecoilState } from "recoil";
import axios from "axios";

function ElegibilityQuestions(props) {
  const [formData, setFormData] = useState([]);
  const [error, setError] = useState();

  function onCheckChanged(e) {
    setError();

    var formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[e.target.id] = e.target.checked;
    console.log(formDataCopy);

    setFormData(formDataCopy);
  }
  function onSelectChanged(e) {
    setError();

    var formDataCopy = JSON.parse(JSON.stringify(formData));
    console.log(formDataCopy);
    formDataCopy[e.target.id] = e.target.value;
    console.log(formDataCopy);

    setFormData(formDataCopy);
  }

  function request(data) {
    axios.defaults.withCredentials = true;
    axios
      .post("https://www.trustyvisas.com/api/elegibility.php", data)
      .then(function (response) {
        if (response.data) {
          console.log(formData);
          setFormData(response.data);
        } else {
          setFormData({});
        }

        if (data) {
          window.location.href = "/review";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    request();
  }, []);

  function next() {
    if (formData?.question1 === "" || !formData?.question1) {
      setError({
        field: "question1",
        message: "Please select an option",
      });
      return;
    }
    if (formData?.question2 === "" || !formData?.question2) {
      setError({
        field: "question2",
        message: "Please select an option",
      });
      return;
    }
    if (formData?.question3 === "" || !formData?.question3) {
      setError({
        field: "question3",
        message: "Please select an option",
      });
      return;
    }
    if (formData?.question4 === "" || !formData?.question4) {
      setError({
        field: "question4",
        message: "Please select an option",
      });
      return;
    }
    if (formData?.question5 === "" || !formData?.question5) {
      setError({
        field: "question5",
        message: "Please select an option",
      });
      return;
    }
    if (formData?.question6 === "" || !formData?.question6) {
      setError({
        field: "question6",
        message: "Please select an option",
      });
      return;
    }
    if (formData?.question7 === "" || !formData?.question7) {
      setError({
        field: "question7",
        message: "Please select an option",
      });
      return;
    }
    if (formData?.question8 === "" || !formData?.question8) {
      setError({
        field: "question8",
        message: "Please select an option",
      });
      return;
    }
    if (formData?.question9 === "" || !formData?.question9) {
      setError({
        field: "question9",
        message: "Please select an option",
      });
      return;
    }
    if (formData?.question10 === "" || !formData?.question10) {
      setError({
        field: "question10",
        message: "Please check box",
      });
      return;
    }
    if (formData?.question11 === "" || !formData?.question11) {
      setError({
        field: "question11",
        message: "Please check box",
      });
      return;
    }
    request(formData);
  }

  return (
    <form>
      <div className="row">
        <div className="col-8">
          <div className="pt-4 pb-4">
            <h5>
              <b style={{ fontSize: "35px" }}>Mandatory Questions</b>
            </h5>
          </div>
        </div>
        <div className="col-xs-12 col-md-4 pb-5 d-block d-md-none">
          <div className="pt-3" style={{ textAlign: "end" }}>
            Progress (Page 5/7)
            <div class="progress" style={{ width: "100%" }}>
              <div
                class="progress-bar progress-bar-striped"
                role="progressbar"
                style={{ width: "100%" }}
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-xs-12 col-md-10 pb-3">
          <p>
            1) Do you have a physical or mental disorder; or are you a drug
            abuser or addict; or do you currently have any of the following
            diseases (communicable diseases are specified pursuant to section
            361(b) of the Public Health Service Act) {formData?.question1}
            <br />
            - Cholera
            <br />
            - Diphtheria
            <br />
            - Tuberculosis, infectious
            <br />
            - Plague
            <br />
            - Smallpox
            <br />
            - Yellow Fever
            <br />
            - Viral Hemorrhagic Fevers, including Ebola, Lassa, Marburg,
            Crimean-Congo
            <br />- Severe acute respiratory illnesses capable of transmission
            to other persons and likely to cause mortality.
          </p>
        </div>
        <div className="col">
          <div class="form-group">
            <select
              class="form-control"
              id="question1"
              onChange={(e) => [onSelectChanged(e), setError()]}
              value={formData?.question1}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "question1" && error.message}
            </small>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-xs-12 col-md-10 pb-3">
          2) Have you ever been arrested or convicted for a crime that resulted
          in serious damage to property, or serious harm to another person or
          government authority?
        </div>
        <div className="col">
          <div class="form-group">
            <select
              class="form-control"
              id="question2"
              onChange={(e) => [onSelectChanged(e), setError()]}
              value={formData?.question2}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "question2" && error.message}
            </small>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-xs-12 col-md-10 pb-3">
          3) Have you ever violated any law related to possessing, using, or
          distributing illegal drugs?
        </div>
        <div className="col">
          <div class="form-group">
            <select
              class="form-control"
              id="question3"
              onChange={(e) => [onSelectChanged(e), setError("")]}
              value={formData?.question3}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "question3" && error.message}
            </small>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-xs-12 col-md-10 pb-3">
          4) Do you seek to engage in or have you ever engaged in terrorist
          activities, espionage, sabotage, or genocide?
        </div>
        <div className="col">
          <div class="form-group">
            <select
              class="form-control"
              id="question4"
              onChange={(e) => [onSelectChanged(e), setError("")]}
              value={formData?.question4}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "question4" && error.message}
            </small>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-xs-12 col-md-10 pb-3">
          5) Have you ever committed fraud or misrepresented yourself or others
          to obtain, or assist others to obtain, a visa or entry into the United
          States?
        </div>
        <div className="col">
          <div class="form-group">
            <select
              class="form-control"
              id="question5"
              onChange={(e) => [onSelectChanged(e), setError("")]}
              value={formData?.question5}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "question5" && error.message}
            </small>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-xs-12 col-md-10 pb-3">
          6) Are you currently seeking employment in the United States or were
          you previously employed in the United States without prior permission
          from the U.S. government?
        </div>
        <div className="col">
          <div class="form-group">
            <select
              class="form-control"
              id="question6"
              onChange={(e) => [onSelectChanged(e), setError()]}
              value={formData?.question6}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "question6" && error.message}
            </small>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-xs-12 col-md-10 pb-3">
          7) Have you ever been denied a U.S. visa you applied for with your
          current or previous passport, or have you ever been refused admission
          to the United States or withdrawn your application for admission at a
          U.S. port of entry?
        </div>
        <div className="col">
          <div class="form-group">
            <select
              class="form-control"
              id="question7"
              onChange={(e) => [onSelectChanged(e), setError()]}
              value={formData?.question7}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "question7" && error.message}
            </small>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-xs-12 col-md-10 pb-3">
          8) Have you ever stayed in the United States longer than the admission
          period granted to you by the U.S. government?
        </div>
        <div className="col">
          <div class="form-group">
            <select
              class="form-control"
              id="question8"
              onChange={(e) => [onSelectChanged(e), setError("")]}
              value={formData?.question8}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "question8" && error.message}
            </small>
          </div>
        </div>
      </div>{" "}
      <div className="row pt-5">
        <div className="col-xs-12 col-md-10 pb-3">
          9) Have you traveled to, or been present in Iraq, Syria, Iran, Sudan,
          Libya, Somalia or Yemen on or after March 1, 2011?
        </div>
        <div className="col">
          <div class="form-group">
            <select
              class="form-control"
              id="question9"
              onChange={(e) => [onSelectChanged(e), setError()]}
              value={formData?.question9}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "question9" && error.message}
            </small>
          </div>
        </div>
      </div>
      <div class="form-check pt-5">
        <input
          class="form-check-input mt-2"
          type="checkbox"
          value={formData?.question10}
          checked={formData?.question10}
          id="question10"
          onChange={(e) => [setError(), onCheckChanged(e)]}
        />
        <label class="form-check-label" for="defaultCheck1">
          I understand all the submitted details are accurate and that any
          incorrect details could lead to a denied application. ESTA
          applications can take up to 72 hours to be processed by the US
          Government and I should check status before traveling.
        </label>
        <small class="form-text " style={{ color: "red" }}>
          {error?.field === "question10" && error.message}
        </small>
      </div>
      <div class="form-check pt-5">
        <input
          class="form-check-input mt-2"
          type="checkbox"
          value={formData?.question11}
          checked={formData?.question11}
          id="question11"
          onChange={(e) => [setError(), onCheckChanged(e), console.log(e)]}
        />
        <label class="form-check-label" for="defaultCheck1">
          I consent to having www.trustyvisas-esta.com collect my information so
          that they can process my ESTA application. For more info check our
          privacy policy where you'll get more info on where, how and why we
          store your data.
        </label>
        <small class="form-text " style={{ color: "red" }}>
          {error?.field === "question11" && error.message}
        </small>
      </div>
      <div class="modal-footer">
        <button
          onClick={() => (window.location.href = "/payment")}
          type="button"
          class="btn btn-primary"
        >
          {"<"} Previous Page
        </button>
        <button onClick={() => next()} type="button" class="btn btn-primary">
          Next Page {">"}
        </button>
      </div>
    </form>
  );
}
export default ElegibilityQuestions;
