import React, { useEffect } from "react";
import Banner from "../Components/Banner";
import Apply2 from "../Components/Apply2";
import Offers from "../Components/Offers";
import FAQSmall from "../Components/FAQSmall";
import Training from "../Components/Training";
import FAQSmall2 from "../Components/FAQSmall2";
import FAQComponent from "../Components/FAQComponent";
import FormModal from "../Components/FormModal";

function Home(props) {
  return (
    <>
      <Banner />
      <Apply2 />
      <Offers />
      <FAQSmall />
      <FAQComponent />
      <Training />
      <FAQSmall2 />
    </>
  );
}
export default Home;
