import React, { useState, useEffect } from "react";
import { formAtom } from "../../Atoms";
import { useRecoilState } from "recoil";
import axios from "axios";

function Disclaimers(props) {
  const [accordionArray, setAccordionArray] = useState([true, false]);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [disclaimerError, setDisclaimerError] = useState("");
  const [tpaError, setTPAError] = useState("");
  const [travelPromotionActAccepted, setTravelPromotionActAccepted] =
    useState(false);

  function selected(value) {
    var copy = accordionArray;
    copy[value] = !copy[value];
    setAccordionArray([...copy]);
  }

  function disclaimers(data) {
    axios.defaults.withCredentials = true;
    axios
      .post("https://www.trustyvisas.com/api/disclaimers.php", data)
      .then(function (response) {
        console.log(response);
        if (response.data.disclaimer === true) {
          setDisclaimerAccepted(true);
        }
        if (response.data.tpa === true) {
          setTravelPromotionActAccepted(true);
          selected(1);
        }
        if (data) {
          window.location.href = "/applicantinfo";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    disclaimers();
  }, []);

  function next() {
    if (disclaimerAccepted === false) {
      setDisclaimerError("You must accept the disclaimer.");
      return;
    }

    if (travelPromotionActAccepted === false) {
      setTPAError("You must accept the Travel Promotion Act");
      return;
    }

    disclaimers({ decleration: true, tpa: true });
  }

  return (
    <section>
      <div class="auto-container pb-5">
        <div className="col-xs-12 col-md-4 pb-5 d-block d-md-none">
          <div className="pt-3" style={{ textAlign: "end" }}>
            Progress (Page 1/7)
            <div class="progress" style={{ width: "100%" }}>
              <div
                class="progress-bar progress-bar-striped"
                role="progressbar"
                style={{ width: "12%" }}
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-12 faq-column">
            <ul class="accordion-box">
              <li
                class={
                  "accordion block" +
                  (accordionArray && accordionArray[0] === true
                    ? " active-block"
                    : "")
                }
              >
                <div class="acc-btn " onClick={() => selected(0)}>
                  <div class="icon-outer">
                    <i class="fas fa-angle-down"></i>
                  </div>
                  <h5>Disclaimer</h5>
                </div>
                <div
                  class={
                    "acc-content" +
                    (accordionArray && accordionArray[0] === true
                      ? " current"
                      : "")
                  }
                >
                  <div _ngcontent-rwn-c37="" class="panel-body">
                    <p _ngcontent-rwn-c37="">
                      The Electronic System for Travel Authorization performs
                      checks against law enforcement databases. All travelers
                      seeking admission to the United States under the Visa
                      Waiver Program are required to obtain an electronic travel
                      authorization using this system prior to being granted
                      boarding.
                    </p>
                    <p _ngcontent-rwn-c37="">
                      If your electronic travel authorization application is
                      approved, it establishes that you are eligible to travel,
                      but does not establish that you are admissible to the
                      United States under the Visa Waiver Program. Upon arrival
                      to the United States, you will be inspected by a U.S.
                      Customs and Border Protection Officer at a port of entry
                      who may determine that you are inadmissible under the Visa
                      Waiver Program or for any reason under United States law.
                    </p>
                    <p _ngcontent-rwn-c37="">
                      A determination that you are not eligible for electronic
                      travel authorization does not preclude you from applying
                      for a visa to travel to the United States.
                    </p>
                    <p _ngcontent-rwn-c37="">
                      All information provided by you, or on your behalf by a
                      designated third party, must be true and correct. An
                      electronic travel authorization may be revoked at any time
                      and for any reason, such as new information influencing
                      eligibility. You may be subject to administrative or
                      criminal penalties if you knowingly and willfully make a
                      materially false, fictitious, or fraudulent statement or
                      representation in an electronic travel authorization
                      application submitted by you or on your behalf.
                    </p>
                    <p _ngcontent-rwn-c37="" class="text-warning pt-4">
                      <b>WARNING:</b> If upon application for admission to the
                      United States at a port of entry you are admitted under
                      the Visa Waiver Program (VWP) by a US Customs and Border
                      Protection Officer, you may not accept unauthorized
                      employment; or attend school; or represent the foreign
                      information media during your visit under the program. You
                      may not apply for: 1) a change of nonimmigrant status, 2)
                      an extension of stay, or 3) adjustment of status to
                      temporary or permanent resident, unless eligible under
                      section 245(c)(4) of the Immigration and Nationality Act.
                      Violation of these terms will subject you to REMOVAL.
                    </p>
                    <p _ngcontent-rwn-c37="" className="pt-4">
                      Please indicate you have read and understand the
                      information provided above:{" "}
                    </p>
                    <div _ngcontent-rwn-c37="" class="radio" className="pt-4">
                      <label
                        _ngcontent-rwn-c37=""
                        class="mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-textfield--full-width mdl-js-ripple-effect--ignore-events is-upgraded"
                        data-upgraded=",MaterialRadio,MaterialRipple"
                      >
                        <input
                          _ngcontent-rwn-c37=""
                          type="radio"
                          name="disclaimerRadios"
                          id="optionsRadios1"
                          value="undefined"
                          class="mdl-radio__button"
                          autocomplete="off"
                          onClick={() => [
                            setDisclaimerAccepted(true),
                            setDisclaimerError(""),
                            selected(1),
                          ]}
                          defaultChecked={
                            disclaimerAccepted.toString() === "true" && "true"
                          }
                        />
                        <span
                          _ngcontent-rwn-c37=""
                          class="mdl-radio__label semibold ml-2"
                        >
                          Yes, I have read and understand the information and
                          agree to these terms.
                        </span>
                        <span class="mdl-radio__outer-circle"></span>
                        <span class="mdl-radio__inner-circle"></span>
                        <span
                          class="mdl-radio__ripple-container mdl-js-ripple-effect mdl-ripple--center"
                          data-upgraded=",MaterialRipple"
                        >
                          <span class="mdl-ripple"></span>
                        </span>
                      </label>
                    </div>
                    <div _ngcontent-rwn-c37="" class="radio">
                      <label
                        _ngcontent-rwn-c37=""
                        class="mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-textfield--full-width mdl-js-ripple-effect--ignore-events is-upgraded"
                        data-upgraded=",MaterialRadio,MaterialRipple"
                      >
                        <input
                          _ngcontent-rwn-c37=""
                          type="radio"
                          name="disclaimerRadios"
                          id="optionsRadios2"
                          value="undefined"
                          data-toggle="modal"
                          data-target="#disclaimerInfo"
                          class="mdl-radio__button"
                          autocomplete="off"
                          onClick={() => [
                            setDisclaimerAccepted(false),
                            setDisclaimerError(""),
                          ]}
                          defaultChecked={
                            disclaimerAccepted.toString() === "false" && "false"
                          }
                        />
                        <span
                          _ngcontent-rwn-c37=""
                          class="mdl-radio__label semibold ml-2"
                        >
                          No, I need additional clarification or I decline to
                          provide acknowledgment.
                        </span>
                        <span class="mdl-radio__outer-circle"></span>
                        <span class="mdl-radio__inner-circle"></span>
                        <span
                          class="mdl-radio__ripple-container mdl-js-ripple-effect mdl-ripple--center"
                          data-upgraded=",MaterialRipple"
                        >
                          <span class="mdl-ripple"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <small class="form-text " style={{ color: "red" }}>
                    {disclaimerError}
                  </small>
                </div>
              </li>
              <li
                class={
                  "accordion block" +
                  (disclaimerAccepted === true ||
                  travelPromotionActAccepted === true
                    ? " active-block"
                    : "")
                }
                onClick={() => selected(1)}
              >
                <div class="acc-btn">
                  <div class="icon-outer">
                    <i class="fas fa-angle-down"></i>
                  </div>
                  <h5>The Travel Promotion Act of 2009</h5>
                </div>
                <div
                  class={
                    "acc-content" +
                    (disclaimerAccepted === true ||
                    travelPromotionActAccepted === true
                      ? " current"
                      : "")
                  }
                >
                  <div
                    _ngcontent-rwn-c37=""
                    id="2collpaseTwoText"
                    class="panel-body"
                  >
                    <p _ngcontent-rwn-c37="">
                      On March 4, 2010, President Obama signed into law the
                      Travel Promotion Act (TPA) of 2009, Pub. L. No. 111-145.
                      The Act directs the Secretary of Homeland Security to
                      establish a fee for the use of the ESTA system, comprised
                      of $10.00 for each VWP applicant receiving authorization
                      to travel to the United States and $4.00 for the
                      processing of the ESTA application. Applicants who are
                      denied authorization to travel to the U.S. under the VWP
                      will only be charged $4.00. The fee may only be paid by
                      credit card or PayPal. Applicants may save the application
                      data and return to the application at a later date to
                      enter the payment information. However, the application
                      will not be submitted for processing until all payment
                      information is completed.
                    </p>
                    <p _ngcontent-rwn-c37="" class="text-warning mt-4 mb-4">
                      <b>WARNING:</b> The administrative fee will be collected
                      by credit card or PayPal. It is crucial that all
                      applicants enter their ESTA and payment information
                      accurately. If information is entered incorrectly, the
                      applicant may be charged additional fees to reapply.
                      Updates to an application will not accrue additional fees.
                      Applicants who do not complete the payment process will
                      not receive authorization to travel to the United States
                      and will not be allowed to board any aircraft or vessel
                      destined for the United States. If an applicant stops
                      payment of the fee, his or her authorization to travel to
                      the United States will be revoked. CBP is not responsible
                      for additional fees that may be charged by the applicant's
                      credit card company or PayPal for the transaction. By
                      pressing the "Apply" button in the application process,
                      applicants agree not to dispute any administrative fee
                      charged by CBP for the use of the ESTA system, and further
                      acknowledge that there are no refunds.
                    </p>
                    <p _ngcontent-rwn-c37="">
                      Please indicate you have read and understand the
                      information provided above:
                    </p>
                    <div _ngcontent-rwn-c37="" class="radio pt-4">
                      <label
                        _ngcontent-rwn-c37=""
                        class="mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-textfield--full-width mdl-js-ripple-effect--ignore-events is-upgraded"
                        data-upgraded=",MaterialRadio,MaterialRipple"
                      >
                        <input
                          _ngcontent-rwn-c37=""
                          type="radio"
                          name="travelRadios"
                          id="travelRadios1"
                          class="mdl-radio__button checked"
                          autocomplete="off"
                          onClick={() => [
                            setTravelPromotionActAccepted(true),
                            setTPAError(""),
                          ]}
                          defaultChecked={
                            travelPromotionActAccepted.toString() === "true" &&
                            "true"
                          }
                        />
                        <span
                          _ngcontent-rwn-c37=""
                          class="mdl-radio__label semibold ml-2"
                        >
                          Yes, I have read and understand the information and
                          agree to these terms.{" "}
                          {travelPromotionActAccepted.toString()}
                        </span>
                        <span class="mdl-radio__outer-circle"></span>
                        <span class="mdl-radio__inner-circle"></span>
                        <span
                          class="mdl-radio__ripple-container mdl-js-ripple-effect mdl-ripple--center"
                          data-upgraded=",MaterialRipple"
                        >
                          <span class="mdl-ripple"></span>
                        </span>
                      </label>
                    </div>
                    <div _ngcontent-rwn-c37="" class="radio">
                      <label
                        _ngcontent-rwn-c37=""
                        class="mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-textfield--full-width mdl-js-ripple-effect--ignore-events is-upgraded"
                        data-upgraded=",MaterialRadio,MaterialRipple"
                      >
                        <input
                          _ngcontent-rwn-c37=""
                          type="radio"
                          name="travelRadios"
                          id="travelRadios2"
                          value="undefined"
                          data-toggle="modal"
                          data-target="#travelInfo"
                          class="mdl-radio__button"
                          autocomplete="off"
                          onClick={() => [
                            setTravelPromotionActAccepted(false),
                            setTPAError(""),
                          ]}
                          defaultChecked={
                            travelPromotionActAccepted.toString() === "false" &&
                            "false"
                          }
                        />
                        <span
                          _ngcontent-rwn-c37=""
                          class="mdl-radio__label semibold ml-2"
                        >
                          No, I need additional clarification or I decline to
                          provide acknowledgment.
                        </span>
                        <span class="mdl-radio__outer-circle"></span>
                        <span class="mdl-radio__inner-circle"></span>
                        <span
                          class="mdl-radio__ripple-container mdl-js-ripple-effect mdl-ripple--center"
                          data-upgraded=",MaterialRipple"
                        >
                          <span class="mdl-ripple"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <small class="form-text " style={{ color: "red" }}>
                    {tpaError}
                  </small>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button onClick={() => next()} type="button" class="btn btn-primary">
          Next Page {">"}
        </button>
      </div>
    </section>
  );
}
export default Disclaimers;
