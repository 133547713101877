import React, { useEffect } from "react";
import { formAtom } from "../Atoms";
import { useRecoilState } from "recoil";

function Menu(props) {
  const [showForm, setShowForm] = useRecoilState(formAtom);

  return (
    <>
      <header class="main-header style-one">
        <div class="header-top">
          <div class="top-inner clearfix">
            <div class="top-left pull-left">
              <ul class="info clearfix">
                <li>
                  <i class="flaticon-open-email-message"></i>
                  <a href="mailto:inquiry@example.com">
                    support@trustyvisas.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="header-upper">
          <div class="outer-container">
            <div class="outer-box clearfix">
              <div class="upper-left pull-left">
                <figure class="logo-box">
                  <a href="/">
                    <img src="assets/images/logo.png" alt="" />
                  </a>
                </figure>
                <div class="btn-box">
                  <a href="/apply" class="theme-btn-one">
                    Apply $99<i class="flaticon-send"></i>
                  </a>
                </div>
              </div>
              <div class="menu-area pull-right">
                <div class="mobile-nav-toggler" onClick={() => props.toggle()}>
                  <i class="icon-bar"></i>
                  <i class="icon-bar"></i>
                  <i class="icon-bar"></i>
                </div>
                <nav class="main-menu navbar-expand-md navbar-light">
                  <div
                    class="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul class="navigation clearfix">
                      <li class="current dropdown">
                        <a href="/">Home</a>
                      </li>
                      <li class="dropdown">
                        <a href="/apply">Apply</a>
                      </li>
                      <li class="dropdown">
                        <a href="/faq">FAQ</a>
                      </li>

                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </nav>
                <div class="menu-right-content clearfix pull-left">
                  <div class="support-box">
                    <i class="fas fa-phone-volume"></i>
                    <p>Any Questions? Mail Us</p>
                    <h3>
                      <a href="tel:12463330079">support@trustyvisas.com</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sticky-header">
          <div class="auto-container">
            <div class="outer-box clearfix">
              <div class="logo-box pull-left">
                <figure class="logo">
                  <a href="/">
                    <img src="assets/images/logo.png" alt="" />
                  </a>
                </figure>
              </div>
              <div class="menu-area pull-right">
                <nav class="main-menu clearfix"></nav>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class=" mobile-menu ">
        <div class="menu-backdrop"></div>
        <div class="close-btn" onClick={() => props.toggle()}>
          <i class="fas fa-times"></i>
        </div>
        <nav class="menu-box">
          <div class="nav-logo">
            <a href="/">
              <img src="assets/images/logo.png" alt="" title="" />
            </a>
          </div>
          <div class="menu-outer">
            <ul class="navigation clearfix">
              <li class="current dropdown">
                <a href="/">Home</a>
              </li>
              <li class="dropdown">
                <a href="/apply">Apply</a>
              </li>
              <li class="dropdown">
                <a href="/faq">FAQ</a>
              </li>

              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
export default Menu;
