import "./App.css";
import React, { useEffect, useState } from "react";
import Menu from "./Components/Menu";
import Footer from "./Components/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import Apply from "./Pages/Apply";
import FAQ from "./Pages/FAQ";
import Contact from "./Pages/Contact";
import FormModal from "./Components/FormModal";
import { formAtom } from "./Atoms";
import { useRecoilState } from "recoil";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import Refund from "./Pages/Refund";

function App() {
  const [showForm, setShowForm] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    if (id) {
      setShowForm(true);
    }
  }, []);

  function hideForm() {
    setShowForm(false);
  }

  return (
    <>
      <div className={showMenu && "mobile-menu-visible"}>
        <Menu toggle={() => setShowMenu(!showMenu)} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/apply" element={<Apply />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/refund-policy" element={<Refund />} />
          <Route exact path="/applicantinfo" element={<Apply page={2} />} />
          <Route exact path="/personalinfo" element={<Apply page={3} />} />
          <Route exact path="/travelinfo" element={<Apply page={4} />} />
          <Route exact path="/elegibility" element={<Apply page={5} />} />
          <Route exact path="/review" element={<Apply page={6} />} />
          <Route exact path="/payment" element={<Apply page={7} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
      {showForm && <FormModal close={() => hideForm()} />}
    </>
  );
}

export default App;
