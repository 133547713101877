import React from "react";

function FAQSmall2() {
  return (
    <section className="choose-section bg-color-3 pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="row">
              <h3>ESTA Visas</h3>
            </div>
            <div className="row bold-text pt-3">
              All eligible international travelers that wish to travel to the
              United States under the ESTA Visa Waiver Program must apply for
              ESTA authorization. We take the stress out of applying for an
              ESTA. Making it a quick, easy and stress free process.
            </div>
            <div className="row pt-5">
              <h3>Disclaimer</h3>
            </div>
            <div className="row bold-text pt-3">
              This is a commercial website, we are not affiliated with the
              government nor part of the US government. To apply ESTA to USA
              through USA Embassies, you will be charged a fee. To book a
              landing ESTA visa under our process, we will charge a service fee
              for providing consultancy, submitting applications and informing
              the status and results.
            </div>
          </div>
          <div className="col-sm-12 col-md-6 p-5">
            <div className="row">
              <h3>Benefits for Travellers on ESTA Approval?</h3>
            </div>
            <div className="row bold-text pt-3" style={{ lineHeight: "25px" }}>
              – Quick and efficient online US visa waiver service
              <br />
              – Right advice to travellers about the online application
              <br />
              – Highly experienced and well groomed professionals on US
              immigration rules do support your application needs
              <br />
              – One click on the ‘Contact Us’ will bring you get you in touch
              with us
              <br />
              – As we do follow the immigration rules strictly our professionals
              will evaluate your application correctly
              <br />
              – For any needful corrections on your application, we will make a
              prompt contact by email
              <br />
              – Unlike Official Government Website, Our Website Provides You
              More Services
              <br />
              – Pay us on your own currency and save from expensive bank charges
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FAQSmall2;
