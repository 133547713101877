import React, { useEffect, useState } from "react";
import axios from "axios";

function Review() {
  const [formData, setFormData] = useState();

  function request(data) {
    axios.defaults.withCredentials = true;
    axios
      .post("https://www.trustyvisas.com/api/review.php", data)
      .then(function (response) {
        console.log(response);
        setFormData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    if (data) {
      window.location.href = "/payment";
    }
  }

  useEffect(() => {
    request();
  }, []);
  return (
    <div>
      <h1 _ngcontent-hpf-c64="">REVIEW YOUR APPLICATION</h1>
      <p _ngcontent-hpf-c64="">
        Please review all information for accuracy before submitting your
        application. If information is inaccurate, select the "Edit" option in
        the top right corner of the application review. Select "CONFIRM &amp;
        CONTINUE" if/when all information is correct.
      </p>

      {formData && (
        <div className="container-fluid pt-5">
          <div className="row">
            <div class="accordion" id="accordionExample">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h2
                    class="mb-0"
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <button
                      class="btn btn-link header-text"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Applicant Information
                    </button>
                    <button
                      class="btn"
                      onClick={() => (window.location.href = "/applicantinfo")}
                    >
                      Edit Section
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">Family Name</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information?.lastName ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">First (Given) Name</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information?.firstName ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">Gender</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information?.gender ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">Date of Birth</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information?.dobDay ?? "-"}{" "}
                        {formData?.applicant_information?.dobMonth ?? "-"}{" "}
                        {formData?.applicant_information?.dobYear ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">City of Birth</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information?.city ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">Country of Birth</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information?.country ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">Passport Number</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information?.passportNumber ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">Country of Citizenship</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information
                          ?.countryOfCitizenship ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">
                          National Identification Number
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information
                          ?.nationalIdentificationNumber ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">Issuance Date</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information?.issuedDay ?? "-"}{" "}
                        {formData?.applicant_information?.issuedMonth ?? "-"}{" "}
                        {formData?.applicant_information?.issuedYear ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-4">
                        <b _ngcontent-hpf-c59="">Expiration Date</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information?.expirationDay ??
                          "-"}{" "}
                        {formData?.applicant_information?.expirationMonth ??
                          "-"}{" "}
                        {formData?.applicant_information?.expirationYear ?? "-"}
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-12">
                        <hr _ngcontent-hpf-c59="" />
                        <h4>Other Citizenship/Nationality</h4>
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          Are you now, a citizen or national of any other
                          country?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information?.otherNationality ??
                          "-"}
                      </div>
                      {formData?.applicant_information?.otherNationality ===
                        "Yes" && (
                        <>
                          {formData?.applicant_information.otherNationalityList.map(
                            function (object, i) {
                              return (
                                <>
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-4"
                                  >
                                    <b _ngcontent-hpf-c59="">
                                      Country of Citizenship / Nationality{" "}
                                    </b>
                                    <br _ngcontent-hpf-c59="" />
                                    {formData?.applicant_information
                                      .otherNationalityList[i]
                                      .citizenshipCountry ?? "-"}
                                  </div>
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-6"
                                  >
                                    <b _ngcontent-hpf-c59="">
                                      How did you acquire citizenship /
                                      nationality from this country
                                    </b>
                                    <br _ngcontent-hpf-c59="" />
                                    {object.howAquireNationality ?? "-"}{" "}
                                  </div>
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-2"
                                  >
                                    <b _ngcontent-hpf-c59="">Other</b>
                                    <br _ngcontent-hpf-c59="" />

                                    {object.other ?? "-"}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          Have you ever been a citizen or national of any other
                          country?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.applicant_information
                          ?.otherNationalityEver ?? "-"}
                      </div>
                      {formData?.applicant_information?.otherNationality ===
                        "Yes" && (
                        <>
                          {formData?.applicant_information.otherNationalityEverList.map(
                            function (object, i) {
                              return (
                                <>
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-4"
                                  >
                                    <b _ngcontent-hpf-c59="">
                                      Country of Citizenship / Nationality
                                    </b>
                                    <br _ngcontent-hpf-c59="" />
                                    {object.citizenEverCountry ?? "-"}
                                  </div>
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-4"
                                  >
                                    <b _ngcontent-hpf-c59="">Date From</b>
                                    <br _ngcontent-hpf-c59="" />
                                    {object.nationalityEverDay ?? "-"}{" "}
                                    {object.nationalityEverMonth ?? "-"}{" "}
                                    {object.nationalityEverYear ?? "-"}{" "}
                                  </div>
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-4"
                                  >
                                    <b _ngcontent-hpf-c59="">Date To</b>
                                    <br _ngcontent-hpf-c59="" />
                                    {object.nationalityEverDayTo ?? "-"}{" "}
                                    {object.nationalityEverMonthTo ?? "-"}{" "}
                                    {object.nationalityEverYearTo ?? "-"}{" "}
                                  </div>
                                </>
                              );
                            }
                          )}
                          <div
                            _ngcontent-hpf-c59=""
                            class="col-xs-12 col-sm-12"
                          >
                            <b _ngcontent-hpf-c59="">Email Address</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.applicant_information.email ?? "-"}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h2
                    class="mb-0"
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <button
                      class="btn btn-link header-text collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Personal Information
                    </button>
                    <button
                      class="btn"
                      onClick={() => (window.location.href = "/personalinfo")}
                    >
                      Edit Section
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse show"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          Are you known by any other names or aliases?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.personal_information?.hasAlias ?? "-"}
                      </div>
                    </div>

                    {formData?.personal_information?.hasAlias === "Yes" && (
                      <>
                        {formData?.personal_information.hasAliasList.map(
                          function (object, i) {
                            return (
                              <>
                                <div className="row">
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-3"
                                  >
                                    <b _ngcontent-hpf-c59="">Given Name</b>
                                    <br _ngcontent-hpf-c59="" />
                                    {object.aliasGivenName ?? "-"}
                                  </div>
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-3"
                                  >
                                    <b _ngcontent-hpf-c59="">Family Name</b>
                                    <br _ngcontent-hpf-c59="" />
                                    {object.aliasLastName ?? "-"}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          Have you ever been issued a passport or national
                          identity card for travel by any other country?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.personal_information?.otherPassport ?? "-"}
                      </div>
                    </div>
                    {formData?.personal_information?.otherPassport ===
                      "Yes" && (
                      <>
                        {formData?.personal_information.otherPassportList.map(
                          function (object, i) {
                            return (
                              <>
                                <div className="row">
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-3"
                                  >
                                    <b _ngcontent-hpf-c59="">Issuing Country</b>
                                    <br _ngcontent-hpf-c59="" />
                                    {object.issuingCountry ?? "-"}
                                  </div>
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-3"
                                  >
                                    <b _ngcontent-hpf-c59="">Document Type</b>
                                    <br _ngcontent-hpf-c59="" />
                                    {object.documentType ?? "-"}
                                  </div>
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-3"
                                  >
                                    <b _ngcontent-hpf-c59="">Document Number</b>
                                    <br _ngcontent-hpf-c59="" />
                                    {object.documentNumber ?? "-"}
                                  </div>
                                  <div
                                    _ngcontent-hpf-c59=""
                                    class="col-xs-12 col-sm-3"
                                  >
                                    <b _ngcontent-hpf-c59="">Expiration Year</b>
                                    <br _ngcontent-hpf-c59="" />
                                    {object.expirationYear ?? "-"}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </>
                    )}
                    <div className="row pt-3">
                      <div className="col-12">
                        <hr _ngcontent-hpf-c59="" />
                        <h4>Contact Information</h4>
                      </div>
                    </div>

                    {formData?.personal_information.contactList.map(function (
                      object,
                      i
                    ) {
                      return (
                        <>
                          <div className="row pt-3">
                            <div
                              _ngcontent-hpf-c59=""
                              class="col-xs-12 col-sm-3"
                            >
                              <b _ngcontent-hpf-c59="">Telephone Type</b>
                              <br _ngcontent-hpf-c59="" />
                              {object.telephoneType ?? "-"}
                            </div>
                            <div
                              _ngcontent-hpf-c59=""
                              class="col-xs-12 col-sm-3"
                            >
                              <b _ngcontent-hpf-c59="">
                                Telephone Country Code
                              </b>
                              <br _ngcontent-hpf-c59="" />
                              {object.telephoneCountryCode ?? "-"}
                            </div>
                            <div
                              _ngcontent-hpf-c59=""
                              class="col-xs-12 col-sm-3"
                            >
                              <b _ngcontent-hpf-c59="">Telephone Number</b>
                              <br _ngcontent-hpf-c59="" />
                              {object.telephoneNumber ?? "-"}
                            </div>
                            <div
                              _ngcontent-hpf-c59=""
                              class="col-xs-12 col-sm-3"
                            >
                              <b _ngcontent-hpf-c59="">Address Line 1</b>
                              <br _ngcontent-hpf-c59="" />
                              {object.addressLine1 ?? "-"}
                            </div>
                            <div
                              _ngcontent-hpf-c59=""
                              class="col-xs-12 col-sm-3"
                            >
                              <b _ngcontent-hpf-c59="">Address Line 2</b>
                              <br _ngcontent-hpf-c59="" />
                              {object.addressLine2 ?? "-"}
                            </div>
                            <div
                              _ngcontent-hpf-c59=""
                              class="col-xs-12 col-sm-3"
                            >
                              <b _ngcontent-hpf-c59="">Apartment Number</b>
                              <br _ngcontent-hpf-c59="" />
                              {object.apartmentNumber ?? "-"}
                            </div>
                            <div
                              _ngcontent-hpf-c59=""
                              class="col-xs-12 col-sm-3"
                            >
                              <b _ngcontent-hpf-c59="">City</b>
                              <br _ngcontent-hpf-c59="" />
                              {object.city ?? "-"}
                            </div>
                            <div
                              _ngcontent-hpf-c59=""
                              class="col-xs-12 col-sm-3"
                            >
                              <b _ngcontent-hpf-c59="">State</b>
                              <br _ngcontent-hpf-c59="" />
                              {object.state ?? "-"}
                            </div>
                            <div
                              _ngcontent-hpf-c59=""
                              class="col-xs-12 col-sm-3"
                            >
                              <b _ngcontent-hpf-c59="">Country</b>
                              <br _ngcontent-hpf-c59="" />
                              {object.country ?? "-"}
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div className="row pt-3 pb-3">
                      <div className="col-12">
                        <hr _ngcontent-hpf-c59="" />
                        <h4>GE Membership</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          Are you a member of the CBP Global Entry Program?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.personal_information?.hasMembership ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">PASSID / Membership Number</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.personal_information?.membershipNumber ??
                          "-"}
                      </div>
                    </div>
                    <div className="row pt-3 pb-3">
                      <div className="col-12">
                        <hr _ngcontent-hpf-c59="" />
                        <h4>Parents</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-auto">
                        <b _ngcontent-hpf-c59="">Family Name</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.personal_information?.parent1LastName ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-auto">
                        <b _ngcontent-hpf-c59="">First (Given) Name</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.personal_information?.parent1FirstName ??
                          "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-auto">
                        <b _ngcontent-hpf-c59="">Family Name</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.personal_information?.parent2LastName ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-auto">
                        <b _ngcontent-hpf-c59="">First (Given) Name</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.personal_information?.parent2FirstName ??
                          "-"}
                      </div>
                    </div>
                    <div className="row pt-3 pb-3">
                      <div className="col-12">
                        <hr _ngcontent-hpf-c59="" />
                        <h4>Employment Information</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          Do you have a current or previous employer?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.personal_information?.employment ?? "-"}
                      </div>
                    </div>

                    {formData?.personal_information?.employment === "Yes" && (
                      <>
                        <div className="row">
                          <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                            <b _ngcontent-hpf-c59="">Job Title</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.personal_information?.jobTitle ?? "-"}
                          </div>
                          <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                            <b _ngcontent-hpf-c59="">Employer Name</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.personal_information?.employerName ??
                              "-"}
                          </div>
                          <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                            <b _ngcontent-hpf-c59="">Address Line 1</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.personal_information
                              ?.employerAddressLine1 ?? "-"}
                          </div>
                          <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                            <b _ngcontent-hpf-c59="">Address Line 2</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.personal_information
                              ?.employerAddressLine2 ?? "-"}
                          </div>
                          <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                            <b _ngcontent-hpf-c59="">Apartment Number</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.personal_information
                              ?.employerApartmentNumber ?? "-"}
                          </div>
                          <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                            <b _ngcontent-hpf-c59="">City</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.personal_information?.employerCity ??
                              "-"}
                          </div>
                          <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                            <b _ngcontent-hpf-c59="">State</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.personal_information?.employerState ??
                              "-"}
                          </div>
                          <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                            <b _ngcontent-hpf-c59="">Country</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.personal_information?.employerCountry ??
                              "-"}
                          </div>
                          <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                            <b _ngcontent-hpf-c59="">Telephone Country Code</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.personal_information
                              ?.employerCountryCode ?? "-"}
                          </div>
                          <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                            <b _ngcontent-hpf-c59="">Telephone Number</b>
                            <br _ngcontent-hpf-c59="" />
                            {formData?.personal_information
                              ?.employerTelephoneNumber ?? "-"}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h2
                    class="mb-0"
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <button
                      class="btn btn-link header-text collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Travel Information
                    </button>
                    <button
                      class="btn"
                      onClick={() => (window.location.href = "/travelinfo")}
                    >
                      Edit Section
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  class="collapse show"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          Is your travel to the US occurring in transit to
                          another country?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.transiting ?? "-"}
                      </div>
                    </div>
                    <div className="row pt-3 pb-3">
                      <div className="col-12">
                        <hr _ngcontent-hpf-c59="" />
                        <h4>U.S. POINT OF CONTACT INFORMATION</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          US Point of Contact (ie Hotel Name)
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.pointOfContact ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Address Line 1</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.addressLine1 ?? "-"}
                      </div>

                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Address Line 2</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.addressLine2 ?? "-"}
                      </div>

                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Apartment Number</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.apartmentNumber ?? "-"}
                      </div>

                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">City</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.city ?? "-"}
                      </div>

                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">State</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.state ?? "-"}
                      </div>

                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Telephone</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.telephoneNumber ?? "-"}
                      </div>
                    </div>
                    <div className="row pt-3 pb-3">
                      <div className="col-12">
                        <hr _ngcontent-hpf-c59="" />
                        <h4>Address While in the US</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          Is your Address While in the U.S. same as the U.S.
                          Point of Contact Address listed above?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.sameAddress ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Address Line 1</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.addressLine1US ?? "-"}
                      </div>

                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Address Line 2</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.addressLine2US ?? "-"}
                      </div>

                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Apartment Number</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.apartmentNumberUS ?? "-"}
                      </div>

                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">City</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.cityUS ?? "-"}
                      </div>

                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">State</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.stateUS ?? "-"}
                      </div>
                    </div>
                    <div className="row pt-3 pb-3">
                      <div className="col-12">
                        <hr _ngcontent-hpf-c59="" />
                        <h4>
                          Emergency Contact Information (In or outside United
                          States)
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Family Name</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.familyName ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">First Name</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.firstName ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Telephone Country Code</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.countryCode ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Telephone Number</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.telephoneNumberEC ?? "-"}
                      </div>
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-3">
                        <b _ngcontent-hpf-c59="">Email Address</b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.travel_information.email ?? "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h2
                    class="mb-0"
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <button
                      class="btn btn-link header-text collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Elegibility
                    </button>
                    <button
                      class="btn"
                      onClick={() => (window.location.href = "/elegibility")}
                    >
                      Edit Section
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  class="collapse show"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <div className="row pt-3 pb-3">
                      <div className="col-12">
                        <hr _ngcontent-hpf-c59="" />
                        <h4>Mandatory Questions</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          1) Do you have a physical or mental disorder; or are
                          you a drug abuser or addict; or do you currently have
                          any of the following diseases (communicable diseases
                          are specified pursuant to section 361(b) of the Public
                          Health Service Act) - Cholera - Diphtheria -
                          Tuberculosis, infectious - Plague - Smallpox - Yellow
                          Fever - Viral Hemorrhagic Fevers, including Ebola,
                          Lassa, Marburg, Crimean-Congo - Severe acute
                          respiratory illnesses capable of transmission to other
                          persons and likely to cause mortality.
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.elegibility_questions.question1 ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          2) Have you ever been arrested or convicted for a
                          crime that resulted in serious damage to property, or
                          serious harm to another person or government
                          authority?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.elegibility_questions.question2 ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          3) Have you ever violated any law related to
                          possessing, using, or distributing illegal drugs?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.elegibility_questions.question3 ?? "-"}
                      </div>
                    </div>

                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          4) Do you seek to engage in or have you ever engaged
                          in terrorist activities, espionage, sabotage, or
                          genocide?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.elegibility_questions.question4 ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          5) Have you ever committed fraud or misrepresented
                          yourself or others to obtain, or assist others to
                          obtain, a visa or entry into the United States?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.elegibility_questions.question5 ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          5) Have you ever committed fraud or misrepresented
                          yourself or others to obtain, or assist others to
                          obtain, a visa or entry into the United States?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.elegibility_questions.question5 ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          6) Are you currently seeking employment in the United
                          States or were you previously employed in the United
                          States without prior permission from the U.S.
                          government?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.elegibility_questions.question6 ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          7) Have you ever been denied a U.S. visa you applied
                          for with your current or previous passport, or have
                          you ever been refused admission to the United States
                          or withdrawn your application for admission at a U.S.
                          port of entry?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.elegibility_questions.question7 ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          8) Have you ever stayed in the United States longer
                          than the admission period granted to you by the U.S.
                          government?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.elegibility_questions.question8 ?? "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div _ngcontent-hpf-c59="" class="col-xs-12 col-sm-12">
                        <b _ngcontent-hpf-c59="">
                          9) Have you traveled to, or been present in Iraq,
                          Syria, Iran, Sudan, Libya, Somalia or Yemen on or
                          after March 1, 2011?
                        </b>
                        <br _ngcontent-hpf-c59="" />
                        {formData?.elegibility_questions.question9 ?? "-"}
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        onClick={() =>
                          (window.location.href = "/applicantinfo")
                        }
                        type="button"
                        class="btn btn-primary"
                      >
                        {"<"} Previous Page
                      </button>
                      <button
                        onClick={() => request(formData)}
                        type="button"
                        class="btn btn-primary"
                      >
                        Save & Continue {">"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Review;
