import React from "react";
import { useEffect } from "react";

function CountryOfCitizenshipDropDown(props) {
  return (
    <select
      class="form-control"
      id={props.id}
      name="country"
      value={props.value}
      onChange={(e) => props.changed(e)}
    >
      <option value=""> Select.. {props.value} </option>
      <option value="AND"> ANDORRA (AND) </option>
      <option value="AUD"> AUSTRALIA (AUS) </option>
      <option value="AUT"> AUSTRIA (AUT) </option>
      <option value="BEL"> BELGIUM (BEL) </option>
      <option value="BRN"> BRUNEI (BRN) </option>
      <option value="CHL"> CHILE (CHL) </option>
      <option value="HRV"> CROATIA (HRV) </option>
      <option value="CZE"> CZECH REPUBLIC (CZE) </option>
      <option value="DNK"> DENMARK (DNK) </option>
      <option value="EST"> ESTONIA (EST) </option>
      <option value="FIN"> FINLAND (FIN) </option>
      <option value="FRA"> FRANCE (FRA) </option>
      <option value="DEU"> GERMANY (DEU) </option>
      <option value="GRC"> GREECE (GRC) </option>
      <option value="HUN"> HUNGARY (HUN) </option>
      <option value="ISL"> ICELAND (ISL) </option>
      <option value="IRL"> IRELAND (IRL) </option>
      <option value="ITA"> ITALY (ITA) </option>
      <option value="JPN"> JAPAN (JPN) </option>
      <option value="LVA"> LATVIA (LVA) </option>
      <option value="LIE"> LIECHTENSTEIN (LIE) </option>
      <option value="LTU"> LITHUANIA (LTU) </option>
      <option value="LUX"> LUXEMBOURG (LUX) </option>
      <option value="MLT"> MALTA (MLT) </option>
      <option value="MCO"> MONACO (MCO) </option>
      <option value="NLD"> NETHERLANDS (NLD) </option>
      <option value="NZL"> NEW ZEALAND (NZL) </option>
      <option value="NOR"> NORWAY (NOR) </option>
      <option value="POL"> POLAND (POL) </option>
      <option value="PRT"> PORTUGAL (PRT) </option>
      <option value="SMR"> SAN MARINO (SMR) </option>
      <option value="SGP"> SINGAPORE (SGP) </option>
      <option value="SVK"> SLOVAKIA (SVK) </option>
      <option value="SVN"> SLOVENIA (SVN) </option>
      <option value="KOR"> SOUTH KOREA (KOR) </option>
      <option value="ESP"> SPAIN (ESP) </option>
      <option value="SWE"> SWEDEN (SWE) </option>
      <option value="CHE"> SWITZERLAND (CHE) </option>
      <option value="TWN"> TAIWAN (TWN) </option>
      <option value="GBR"> UK - BRITISH CITIZEN (GBR) </option>
      <option value="GBD"> UK - BRITISH DTC (GBD) </option>
      <option value="GBN"> UK - BRITISH NATIONAL (O) (GBN) </option>
      <option value="GBO"> UK - BRITISH OVERSEAS (GBO) </option>
      <option value="GBS"> UK - BRITISH SUBJECT (GBS) </option>
      <option value="GBP"> UK - PROTECTED PERSON (GBP) </option>
    </select>
  );
}
export default CountryOfCitizenshipDropDown;
