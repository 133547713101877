import React from "react";

function Footer() {
  return (
    <footer class="main-footer bg-color-2">
      <div class="auto-container">
        <div class="footer-top">
          <div class="widget-section">
            <div class="row clearfix">
              <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div class="footer-widget logo-widget">
                  <figure class="footer-logo">
                    <a href="index.html">
                      <img src="assets/images/logo.png" alt="" />
                    </a>
                  </figure>
                  <div class="text">
                    <p>
                      Integer lobortis sem consequat imperdiet In nulla viverra
                      ut lorem ut, dapibus conse etur diam. Nun bibendum diet
                      condiment sed ipsum duis lacinia.
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-9 col-md-6 col-sm-12 footer-column"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div class="footer-widget links-widget">
                  <div class="widget-title">
                    <h3>Quick Links</h3>
                  </div>
                  <div class="widget-content">
                    <ul class="list clearfix">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/apply">Apply Now</a>
                      </li>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                      <li>
                        <a href="/contact">Contact Us</a>
                      </li>
                      <li>
                        <a href="/terms">Terms</a>
                      </li>
                      <li>
                        <a href="/privacy">Privacy</a>
                      </li>
                      <li>
                        <a href="/refund-policy">Refund Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom clearfix">
          <div class="copyright pull-left">
            <p>
              (&copy;) 2020 <a href="index.html">VISARZO</a> Immigration & Visa
              Firm. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
