import React from "react";

function Apply2() {
  return (
    <section class="apply-style-two">
      <div class="auto-container">
        <div class="top-inner">
          <div class="row clearfix">
            <div class="col-lg-5 col-md-12 col-sm-12 title-column">
              <div class="sec-title">
                <p>Start Your Application</p>
                <h2>Following Steps Apply Online Visa</h2>
                <div class="dotted-box">
                  <span class="dotted"></span>
                  <span class="dotted"></span>
                  <span class="dotted"></span>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12 text-column">
              <div class="text">
                <p>
                  Get your ESTA application confirmation emailed to you. Your
                  ESTA Via will be processed within 24 hours. You can pay
                  securely by any debit or credit card. Email updates and email
                  support included.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-lg-4 col-md-6 col-sm-12 single-column">
            <div
              class="single-item wow fadeInUp animated animated"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <div class="inner-box">
                <h6>Step 1</h6>
                <div class="icon-box">
                  <div
                    class="arrow"
                    style={{
                      backgroundImage: "url(assets/images/icons/arrow-1.png)",
                    }}
                  ></div>
                  <i class="flaticon-document"></i>
                </div>
                <h3>
                  <a href="index-2.html">
                    Fill In The <br />
                    Required Form
                  </a>
                </h3>
                <p>
                  You can apply for the ESTA visa for United States by
                  completing the online ESTA application form, which takes about
                  5 minutes.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 single-column">
            <div
              class="single-item wow fadeInUp animated animated"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div class="inner-box">
                <h6>Step 2</h6>
                <div class="icon-box">
                  <div
                    class="arrow"
                    style={{
                      backgroundImage: "url(assets/images/icons/arrow-1.png)",
                    }}
                  ></div>
                  <i class="flaticon-copy"></i>
                </div>
                <h3>
                  <a href="index-2.html">
                    Online <br />
                    Checkout
                  </a>
                </h3>
                <p>
                  Pay the visa easily and securely with any debit or credit
                  card.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 single-column">
            <div
              class="single-item wow fadeInUp animated animated"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div class="inner-box">
                <h6>Step 3</h6>
                <div class="icon-box">
                  <i class="flaticon-plane"></i>
                </div>
                <h3>
                  <a href="index-2.html">
                    Get Ready To <br />
                    Receive your Visa
                  </a>
                </h3>
                <p>
                  The ESTA confirmation will be sent to you by e-mail within 24
                  hours of applying.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Apply2;
