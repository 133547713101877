import React from "react";

function FAQSmall() {
  return (
    <section className="choose-section bg-color-3 pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-4 p-4">
            <div className="row">
              <h3>What is Visa Waiver Program?</h3>
            </div>
            <div className="row bold-text pt-3">
              Visa Waiver Program is a United States government initiative that
              helps citizens from within the limited set of Visa Waiver
              Countries to enter the United States for either tourism, transit
              or business purposes for up to 90 days without needing to obtain a
              traditional visa. So it helps travelers to plan a quick trip to
              the US within a short period of time.
            </div>
          </div>
          <div className="col-xs-12 col-md-4 p-4">
            <div className="row">
              <h3>Who Needs to Apply for ESTA?</h3>
            </div>
            <div className="row bold-text pt-3">
              All travelers visiting United States (by air or sea) under the
              Visa Waiver Program are required to hold a valid ESTA travel
              authorization. The purpose of ESTA is to pre-screen all Visa
              Waiver Travelers before they leave their respective countries
              followed below.
            </div>
          </div>
          <div className="col-xs-12 col-md-4 p-4">
            <div className="row">
              <h3>What are the requirements?</h3>
            </div>
            <div className="row bold-text pt-3">
              1) A valid passport issued by a country covered under the Visa
              Waiver Program
              <br />
              2) You are travelling to the US for a stay of 90 days or less for
              business or tourism purposes
              <br />
              3) You have a valid return or an onward ticket
              <br />
              4) You currently do not hold a visitor’s visa
              <br />
              5) Submit your application at least 72 hours prior to travel.
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FAQSmall;
