import React from "react";

function Contact() {
  return (
    <>
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-6.jpg)",
        }}
      >
        <div class="auto-container">
          <div class="content-box">
            <div class="title-box">
              <h1>Contact Us</h1>
              <div class="dotted-box">
                <span class="dotted"></span>
                <span class="dotted"></span>
                <span class="dotted"></span>
              </div>
            </div>
            <ul class="bread-crumb clearfix">
              <li>
                <i class="flaticon-home-1"></i>
                <a href="/">Home</a>
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="contact-page-section">
        <div class="auto-container">
          <div class="contact-form-inner">
            <div class="sec-title centred">
              <h2>Let’s Start a Conversation Today!</h2>
              <div class="dotted-box">
                <span class="dotted"></span>
                <span class="dotted"></span>
                <span class="dotted"></span>
              </div>
            </div>
            <div class="form-inner">
              <form
                method="post"
                action="assets/inc/sendemail.php"
                id="contact-form"
                class="default-form"
              >
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <input
                      type="text"
                      name="username"
                      placeholder="Your Name "
                      required=""
                    />
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      required=""
                    />
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <input
                      type="text"
                      name="phone"
                      required=""
                      placeholder="Phone No."
                    />
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                    <input
                      type="text"
                      name="subject"
                      required=""
                      placeholder="Subject"
                    />
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                    <textarea name="message" placeholder="Message"></textarea>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                    <button
                      class="theme-btn-two"
                      type="submit"
                      name="submit-form"
                    >
                      <i class="flaticon-send"></i>Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Contact;
