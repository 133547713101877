import React from "react";

function MonthDropdown(props) {
  return (
    <select
      class="form-control"
      id={props.id}
      onChange={(e) => props.onChange(e)}
      value={props.value}
    >
      {}
      <option value="Month">Month</option>
      <option value="Jan">Jan</option>
      <option value="Feb">Feb</option>
      <option value="Mar">Mar</option>
      <option value="Apr">Apr</option>
      <option value="May">May</option>
      <option value="Jun">Jun</option>
      <option value="Jul">Jul</option>
      <option value="Aug">Aug</option>
      <option value="Sep">Sep</option>
      <option value="Oct">Oct</option>
      <option value="Nov">Nov</option>
      <option value="Dec">Dec</option>
    </select>
  );
}
export default MonthDropdown;
