import React, { useEffect, useState } from "react";
import CountryOfCitizenshipDropDown from "../Dropdowns/CountryOfCitizenshipDropDown";
import axios from "axios";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import CountryDropdown from "../CountryDropdown";

function createDaysOfMonth() {
  var elements = [];
  for (let i = 1; i < 32; i++) {
    elements.push(<option value={i}>{i}</option>);
  }
  return elements;
}

function createYears() {
  var currentTime = new Date();
  var year = currentTime.getFullYear();
  var elements = [];
  for (let i = year; i > year - 120; i--) {
    elements.push(<option value={i}>{i}</option>);
  }
  return elements;
}

function ApplicantInformation(props) {
  const [NIRequired, setNIRequired] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState();
  const [errorNested, setErrorNested] = useState();

  function request(data) {
    axios.defaults.withCredentials = true;
    axios
      .post("https://www.trustyvisas.com/api/applicantinformation.php", data)
      .then(function (response) {
        if (response.data) {
          setFormData(response.data);
        }

        if (data) {
          window.location.href = "/personalinfo";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    request();
  }, []);

  function onSelectChangedNested(e, key, id) {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[id][key][e.target.id] = e.target.value;

    setFormData(formDataCopy);
  }

  function onSelectChanged(e) {
    if (e.target.id === "otherNationality") {
      if (e.target.value === "Yes") {
        const formDataCopy = JSON.parse(JSON.stringify(formData));
        formDataCopy.otherNationality = e.target.value;
        formDataCopy.otherNationalityList = [
          { citizenshipCountry: "", howAquireNationality: "", other: "" },
        ];

        setFormData(formDataCopy);
      } else {
        const formDataCopy = JSON.parse(JSON.stringify(formData));
        formDataCopy.otherNationality = e.target.value;
        formDataCopy.otherNationalityList = [];

        setFormData(formDataCopy);
      }
      return;
    }

    if (e.target.id === "otherNationalityEver") {
      if (e.target.value === "Yes") {
        const formDataCopy = JSON.parse(JSON.stringify(formData));
        formDataCopy.otherNationalityEver = e.target.value;
        formDataCopy.otherNationalityEverList = [
          {
            citizenEverCountry: "",
            nationalityEverDay: "",
            nationalityEverMonth: "",
            nationalityEverYear: "",
            nationalityEverDayTo: "",
            nationalityEverMonthTo: "",
            nationalityEverYearTo: "",
          },
        ];

        setFormData(formDataCopy);
      } else {
        const formDataCopy = JSON.parse(JSON.stringify(formData));
        formDataCopy.otherNationalityEver = e.target.value;
        formDataCopy.otherNationalityEverList = [];

        setFormData(formDataCopy);
      }
      return;
    }

    if (e.target.id == "countryOfCitizenship") {
      if (
        e.target.value === "BEL" ||
        e.target.value === "BRN" ||
        e.target.value === "CHL" ||
        e.target.value === "HRV" ||
        e.target.value === "CZE" ||
        e.target.value === "EST" ||
        e.target.value === "DEU" ||
        e.target.value === "GRC" ||
        e.target.value === "HUN" ||
        e.target.value === "LUX" ||
        e.target.value === "MLT" ||
        e.target.value === "MCO" ||
        e.target.value === "NLD" ||
        e.target.value === "PRT" ||
        e.target.value === "SGP" ||
        e.target.value === "SVK" ||
        e.target.value === "SVN" ||
        e.target.value === "KOR" ||
        e.target.value === "ESP"
      ) {
        setNIRequired(true);
      } else {
        setNIRequired(false);
      }
      updateFormData(e);
      return;
    }
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[e.target.id] = e.target.value;

    setFormData(formDataCopy);
  }

  function addRow() {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy.otherNationalityList[
      formDataCopy.otherNationalityList.length
    ] = {
      citizenshipCountry: "",
      howAquireNationality: "",
      other: "",
    };

    setFormData(formDataCopy);
  }

  function addRowEver() {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy.otherNationalityEverList[
      formDataCopy.otherNationalityEverList.length
    ] = {
      citizenEverCountry: "",
      nationalityEverDay: "",
      nationalityEverMonth: "",
      nationalityEverYear: "",
      nationalityEverDayTo: "",
      nationalityEverMonthTo: "",
      nationalityEverYearTo: "",
    };

    setFormData(formDataCopy);
  }

  function removeRow(key) {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy.otherNationalityList.splice(key, 1);
    setFormData(formDataCopy);
  }
  function removeRowEver(key) {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy.otherNationalityEverList.splice(key, 1);
    setFormData(formDataCopy);
  }
  function next() {
    if (!formData.lastName || formData.lastName.length === 0) {
      setError({
        field: "lastName",
        message: "Family name cannot be empty.",
      });
      return;
    }

    if (!formData.firstName || formData.firstName.length === 0) {
      alert(formData.firstName);
      setError({
        field: "firstName",
        message: "Given / first name cannot be empty.",
      });
      return;
    }
    if (!formData.gender || formData.gender === "Select") {
      setError({
        field: "gender",
        message: "Please select a gender",
      });
      return;
    }

    if (
      formData.dobDay === "Day" ||
      formData.dobMonth === "Month" ||
      formData.dobYear === "Year" ||
      !formData.dobDay ||
      !formData.dobMonth ||
      !formData.dobYear
    ) {
      setError({
        field: "dob",
        message: "Date of birth invalid",
      });
      return;
    }

    if (!formData.city || formData.city.length === 0) {
      setError({
        field: "city",
        message: "City cannot be empty",
      });
      return;
    }
    if (formData.country === "Country") {
      setError({
        field: "country",
        message: "Please Select a country",
      });
      return;
    }
    if (!formData.passportNumber || formData.passportNumber.length === 0) {
      setError({
        field: "passportNumber",
        message: "Passport Number invalid",
      });
      return;
    }
    if (
      !formData.countryOfCitizenship ||
      formData.countryOfCitizenship === "Country"
    ) {
      setError({
        field: "countryOfCitizenship",
        message: "Please select country of citizenship",
      });
      return;
    }

    if (
      NIRequired &&
      (formData.identificationNumber === "" || !formData.identificationNumber)
    ) {
      setError({
        field: "identificationNumber",
        message: "Please enter you national idenitifcation number.",
      });
      return;
    }
    if (
      !formData.issuedDay ||
      formData.issuedDay === "Day" ||
      formData.issuedMonth === "Month" ||
      !formData.issuedMonth ||
      formData.issuedMonth === "Year" ||
      !formData.issuedYear
    ) {
      setError({
        field: "issued",
        message: "Issued date is not valid.",
      });
      return;
    }
    if (
      !formData.expirationDay ||
      formData.expirationDay === "Day" ||
      formData.expirationMonth === "Month" ||
      !formData.expirationMonth ||
      formData.expirationMonth === "Year" ||
      !formData.expirationYear
    ) {
      setError({
        field: "expiration",
        message: "Expiration date is not valid.",
      });
      return;
    }
    if (!formData.otherNationality || formData.otherNationality === "") {
      setError({
        field: "hasOtherNationality",
        message: "Please select an option.",
      });
      return;
    }

    for (let i = 0; i < formData.otherNationalityList.length; i++) {
      if (formData.otherNationalityList[i].citizenshipCountry === "") {
        setErrorNested({
          id: "citizenshipCountry",
          key: i,
          message: "Citizenship Country cannot be empty",
        });
        return;
      }
      if (formData.otherNationalityList[i].howAquireNationality === "") {
        setErrorNested({
          id: "howAquireNationality",
          key: i,
          message: "Please select an option",
        });
        return;
      }
    }

    if (
      !formData.otherNationalityEver ||
      formData.otherNationalityEver === ""
    ) {
      setError({
        field: "otherNationalityEver",
        message: "Please select an option.",
      });
      return;
    }

    for (let i = 0; i < formData.otherNationalityEverList.length; i++) {
      if (formData.otherNationalityEverList[i].citizenEverCountry === "") {
        setErrorNested({
          id: "citizenshipEverCountry",
          key: i,
          message: "Citizenship Country cannot be empty",
        });
        return;
      }
      if (
        formData.otherNationalityEverList[i].nationalityEverDay === "" ||
        formData.otherNationalityEverList[i].nationalityEverMonth === "" ||
        formData.otherNationalityEverList[i].nationalityEverYear === ""
      ) {
        setErrorNested({
          id: "citizenshipEverCountryFrom",
          key: i,
          message: "Date is invalid",
        });
        return;
      }
      if (
        formData.otherNationalityEverList[i].nationalityEverDayTo === "" ||
        formData.otherNationalityEverList[i].nationalityEverMonthTo === "" ||
        formData.otherNationalityEverList[i].nationalityEverYearTo === ""
      ) {
        setErrorNested({
          id: "citizenshipEverCountryTo",
          key: i,
          message: "Date is invalid",
        });
        return;
      }
    }

    if (
      formData?.email === "" ||
      formData?.emailConfirm === "" ||
      !formData.email
    ) {
      setError({
        field: "email",
        message: "Email is invalid",
      });
      return;
    }
    if (formData?.email !== formData?.emailConfirm || !formData.email) {
      setError({
        field: "email",
        message: "Email is invalid",
      });
      return;
    }

    request(formData);
  }
  function updateFormData(e) {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy[e.target.id] = e.target.value;
    setFormData(formDataCopy);
  }
  function updateFormDataNested(e, key) {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    formDataCopy.otherNationalityList[key][e.target.id] = e.target.value;
    setFormData(formDataCopy);
  }

  return (
    <form>
      <div className="row">
        <div className="col-12">
          <div className="row pb-4">
            <div className="col-xs-12 col-md-4 pb-5 d-block d-md-none">
              <div className="pt-3" style={{ textAlign: "end" }}>
                Progress (Page 2/7)
                <div class="progress" style={{ width: "100%" }}>
                  <div
                    class="progress-bar progress-bar-striped"
                    role="progressbar"
                    style={{ width: "12%" }}
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="pt-4 pb-4">
                <h5 style={{ fontSize: "35px" }}>
                  <b>ENTER APPLICANT INFORMATION</b>
                </h5>
              </div>
              <p _ngcontent-pnd-c50="" id="applicationInfoText">
                The following information is required of every non-immigrant
                visitor not in possession of a visitor's visa who is a national
                of one of the countries listed in 8 CFR 217.2. Please enter all
                information requested. Each member of your traveling party must
                complete a separate application.
              </p>
              <p _ngcontent-pnd-c50="" className="pt-4 pb-4">
                <strong _ngcontent-pnd-c50="">
                  <b>Please provide all responses in English.</b>
                  <br />
                  <span class="esta-application-required">
                    Required fields are indicated by an asterisk *
                  </span>
                </strong>
              </p>
            </div>
            <div className="col-12">
              <div className="pt-4 pb-2">
                <h5 style={{ fontSize: "25px" }}>
                  <b>APPLICANT / PASSPORT INFORMATION</b>
                </h5>
              </div>
              <p _ngcontent-pnd-c50="" id="applicationInfoText">
                Refer to your <b>passport</b> and enter all information in the
                same format.
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div class="form-group">
            <label
              for="exampleFormControlInput1"
              data-toggle="tooltip"
              data-placement="top"
              title="Tooltip on top"
            >
              Family Name *
            </label>
            <input
              type="text"
              id="lastName"
              class="form-control"
              placeholder="Last Name"
              value={formData?.lastName}
              onChange={(e) => [setError(), updateFormData(e)]}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "lastName" && error.message}
            </small>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div class="form-group d-block">
            <label
              for="exampleFormControlInput1"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              First (Given) Name *
            </label>
            <div class="form-row">
              <div className="col">
                <input
                  type="text"
                  id="firstName"
                  class="form-control"
                  placeholder="First Name"
                  value={formData?.firstName}
                  autoComplete="off"
                  onChange={(e) => [setError(), updateFormData(e)]}
                />
              </div>
            </div>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "firstName" && error.message}
            </small>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Gender? *</label>
            <select
              class="form-control"
              id="gender"
              onChange={(e) => [setError(), onSelectChanged(e)]}
              value={formData?.gender}
            >
              <option value="Select">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "gender" && error.message}
            </small>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <label for="exampleFormControlSelect1">Date of Birth *</label>
          <div className="row">
            <div class="col-3">
              <select
                class="form-control"
                id="dobDay"
                onChange={(e) => [setError(), onSelectChanged(e)]}
                value={formData?.dobDay}
              >
                <option value="Day">Day</option>
                {createDaysOfMonth()}
              </select>
            </div>
            <div class="col-3">
              <MonthDropdown
                id="dobMonth"
                value={formData?.dobMonth}
                onChange={(e) => [setError(), onSelectChanged(e)]}
              />
            </div>
            <div class="col-3">
              <select
                class="form-control"
                id="dobYear"
                onChange={(e) => [setError(), onSelectChanged(e)]}
                value={formData?.dobYear}
              >
                {}
                <option vlaue="Year">Year</option>
                {createYears()}
              </select>
            </div>
          </div>
          <small class="form-text " style={{ color: "red" }}>
            {error?.field === "dob" && error.message}
          </small>
        </div>

        <div className="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">City of Birth *</label>
            <input
              type="text"
              id="city"
              class="form-control"
              placeholder="City of Birth"
              onChange={(e) => [setError(), updateFormData(e)]}
              value={formData?.city}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "city" && error.message}
            </small>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">Country of Birth *</label>
            <CountryDropdown
              id="country"
              onChange={(e) => [updateFormData(e), setError()]}
              value={formData?.country}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "country" && error.message}
            </small>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">Passport Number *</label>
            <input
              type="text"
              id="passportNumber"
              class="form-control"
              placeholder="Passport Number"
              onChange={(e) => [setError(), updateFormData(e)]}
              value={formData?.passportNumber}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "passportNumber" && error.message}
            </small>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">
              Country of Citizenship *
            </label>
            <CountryOfCitizenshipDropDown
              id="countryOfCitizenship"
              value={formData?.countryOfCitizenship}
              changed={(e) => [onSelectChanged(e), setError()]}
            />

            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "countryOfCitizenship" && error.message}
            </small>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">
              National Identification Number
              {NIRequired === true ? " *" : " (Not required)"}
            </label>
            <input
              type="text"
              id="identificationNumber"
              class="form-control"
              placeholder="National Identification Number"
              onChange={(e) => [updateFormData(e), setError()]}
              value={formData?.identificationNumber}
              disabled={!NIRequired}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "identificationNumber" && error.message}
            </small>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <label for="exampleFormControlSelect1">Issuance Date *</label>
          <div className="row">
            <div class="col-3">
              <select
                class="form-control"
                id="issuedDay"
                onChange={(e) => [setError(), updateFormData(e)]}
                value={formData?.issuedDay}
              >
                <option value="">Day</option>
                {createDaysOfMonth()}
              </select>
            </div>
            <div class="col-3">
              <select
                class="form-control"
                id="issuedMonth"
                onChange={(e) => [setError(), updateFormData(e)]}
                value={formData?.issuedMonth}
              >
                <option value="">Month</option>
                <option value="Jan">Jan</option>
                <option value="Feb">Feb</option>
                <option value="Mar">Mar</option>
                <option value="Apr">Apr</option>
                <option value="May">May</option>
                <option value="Jun">Jun</option>
                <option value="Jul">Jul</option>
                <option value="Aug">Aug</option>
                <option value="Sep">Sep</option>
                <option value="Oct">Oct</option>
                <option value="Nov">Nov</option>
                <option value="Dec">Dec</option>
              </select>
            </div>
            <div class="col-3">
              <select
                class="form-control"
                id="issuedYear"
                onChange={(e) => [setError(), updateFormData(e)]}
                value={formData?.issuedYear}
              >
                {}
                <option value="">Year</option>
                {createYears()}
              </select>
            </div>
          </div>
          <small class="form-text " style={{ color: "red" }}>
            {error?.field === "issued" && error.message}
          </small>
        </div>

        <div className="col-12 col-md-6">
          <label for="exampleFormControlSelect1">Expiration Date *</label>
          <div className="row">
            <div class="col-3">
              <select
                class="form-control"
                id="expirationDay"
                onChange={(e) => [setError(), onSelectChanged(e)]}
                value={formData?.expirationDay}
              >
                <option value="">Day</option>
                {createDaysOfMonth()}
              </select>
            </div>
            <div class="col-3">
              <select
                class="form-control"
                id="expirationMonth"
                onChange={(e) => [setError(), onSelectChanged(e)]}
                value={formData?.expirationMonth}
              >
                <option value="">Month</option>
                <option value="Jan">Jan</option>
                <option value="Feb">Feb</option>
                <option value="Mar">Mar</option>
                <option value="Apr">Apr</option>
                <option value="May">May</option>
                <option value="Jun">Jun</option>
                <option value="Jul">Jul</option>
                <option value="Aug">Aug</option>
                <option value="Sep">Sep</option>
                <option value="Oct">Oct</option>
                <option value="Nov">Nov</option>
                <option value="Dec">Dec</option>
              </select>
            </div>
            <div class="col-3">
              <select
                class="form-control"
                id="expirationYear"
                onChange={(e) => [setError(), onSelectChanged(e)]}
                value={formData?.expirationYear}
              >
                <option value="">Year</option>
                {createYears()}
              </select>
            </div>
          </div>
          <small class="form-text " style={{ color: "red" }}>
            {error?.field === "expiration" && error.message}
          </small>
        </div>

        <div className="col-12">
          <div className="pt-5 pb-2">
            <h5 style={{ fontSize: "25px" }}>
              <b>OTHER CITIZENSHIP/NATIONALITY</b>
            </h5>
          </div>
        </div>
        <div className="col-12">
          <div class="form-group">
            <label for="exampleFormControlSelect1">
              Are you now, a citizen or national of any other country? *
            </label>
            <select
              class="form-control"
              id="otherNationality"
              onChange={(e) => [setError(), onSelectChanged(e)]}
              value={formData?.otherNationality}
            >
              <option value="">Select</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "hasOtherNationality" && error.message}
            </small>
          </div>

          {formData?.otherNationalityList &&
            formData?.otherNationalityList.map((item, key) => (
              <div className="col-12">
                <div className="row add-row">
                  <div className="col-12 col-lg-3">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">
                        Country of Citizenship / Nationality *
                      </label>
                      <CountryOfCitizenshipDropDown
                        id="citizenshipCountry"
                        value={
                          formData?.otherNationalityList[key].citizenshipCountry
                        }
                        changed={(e) => [
                          onSelectChangedNested(e, key, "otherNationalityList"),
                          setErrorNested(),
                        ]}
                      />

                      <small class="form-text " style={{ color: "red" }}>
                        {errorNested?.id === "citizenshipCountry" &&
                          errorNested.key === key &&
                          errorNested.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">
                        How did you acquire citizenship / nationality from this
                        country *
                      </label>
                      <select
                        class="form-control"
                        id="howAquireNationality"
                        onChange={(e) => [
                          onSelectChangedNested(e, key, "otherNationalityList"),
                          setErrorNested(""),
                        ]}
                        value={
                          formData?.otherNationalityList[key]
                            .howAquireNationality
                        }
                      >
                        <option value="Select">Select</option>
                        <option value="By Birth">By Birth</option>
                        <option value="Through Parents">Through Parents</option>
                        <option value="Naturalized">Naturalized</option>
                        <option value="other">Other</option>
                      </select>
                      <small class="form-text " style={{ color: "red" }}>
                        {errorNested?.id === "howAquireNationality" &&
                          errorNested.key === key &&
                          errorNested.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-lg-2">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Other</label>
                      <input
                        type="text"
                        id="other"
                        class="form-control"
                        placeholder=""
                        onChange={(e) =>
                          updateFormDataNested(e, key, "otherNationalityList")
                        }
                        value={formData?.otherNationalityList[key].other}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-2">
                    <div class="form-group">
                      <label
                        class="control-label"
                        style={{ color: "white" }}
                        for="btn"
                      >
                        .
                      </label>
                      {formData.otherNationalityList.length > 1 && (
                        <button
                          onClick={() => [removeRow(key), setErrorNested()]}
                          type="button"
                          class="btn btn-danger form-control"
                        >
                          - Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {key + 1 === formData.otherNationalityList.length && (
                  <div className="col-12">
                    <div className="row pb-5">
                      <button
                        onClick={() => addRow()}
                        type="button"
                        class="btn btn-secondary form-control"
                      >
                        + Add Another
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>

        <div className="col-12">
          <div class="form-group">
            <label for="exampleFormControlSelect1">
              Have you ever been a citizen or national of any other country? *
            </label>
            <select
              class="form-control"
              id="otherNationalityEver"
              onChange={(e) => [onSelectChanged(e), setError()]}
              value={formData?.otherNationalityEver}
            >
              <option value="">Select</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "otherNationalityEver" && error.message}
            </small>
          </div>
          {formData?.otherNationalityEverList &&
            formData?.otherNationalityEverList.map((item, key) => (
              <>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">
                          Country of Citizenship / Nationality *
                        </label>
                        <CountryDropdown
                          id="citizenEverCountry"
                          onChange={(e) => [
                            onSelectChangedNested(
                              e,
                              key,
                              "otherNationalityEverList"
                            ),
                            setErrorNested(),
                          ]}
                          value={
                            formData?.otherNationalityEverList[key]
                              .citizenEverCountry
                          }
                        />
                        <small class="form-text " style={{ color: "red" }}>
                          {errorNested?.id === "citizenshipEverCountry" &&
                            errorNested.key === key &&
                            errorNested.message}
                        </small>
                      </div>
                    </div>

                    <div className="col-12 col-md-3">
                      <label for="exampleFormControlSelect1">Date From *</label>
                      <div className="row">
                        <div class="col-4">
                          <select
                            class="form-control"
                            id="nationalityEverDay"
                            onChange={(e) => [
                              setErrorNested(),
                              onSelectChangedNested(
                                e,
                                key,
                                "otherNationalityEverList"
                              ),
                            ]}
                            value={
                              formData?.otherNationalityEverList[key]
                                .nationalityEverDay
                            }
                          >
                            {}
                            <option value="">Day</option>
                            {createDaysOfMonth()}
                          </select>
                        </div>
                        <div class="col-4">
                          <select
                            class="form-control"
                            id="nationalityEverMonth"
                            onChange={(e) => [
                              setErrorNested(),
                              onSelectChangedNested(
                                e,
                                key,
                                "otherNationalityEverList"
                              ),
                            ]}
                            value={
                              formData?.otherNationalityEverList[key]
                                .nationalityEverMonth
                            }
                          >
                            <option value="">Month</option>
                            <option value="Jan">Jan</option>
                            <option value="Feb">Feb</option>
                            <option value="Mar">Mar</option>
                            <option value="Apr">Apr</option>
                            <option value="May">May</option>
                            <option value="Jun">Jun</option>
                            <option value="Jul">Jul</option>
                            <option value="Aug">Aug</option>
                            <option value="Sep">Sep</option>
                            <option value="Oct">Oct</option>
                            <option value="Nov">Nov</option>
                            <option value="Dec">Dec</option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select
                            class="form-control"
                            id="nationalityEverYear"
                            onChange={(e) => [
                              setErrorNested(),
                              onSelectChangedNested(
                                e,
                                key,
                                "otherNationalityEverList"
                              ),
                            ]}
                            value={
                              formData?.otherNationalityEverList[key]
                                .nationalityEverYear
                            }
                          >
                            {}
                            <option vlaue="">Year</option>
                            {createYears()}
                          </select>
                        </div>
                      </div>
                      <small class="form-text " style={{ color: "red" }}>
                        {errorNested?.id === "citizenshipEverCountryFrom" &&
                          errorNested.key === key &&
                          errorNested.message}
                      </small>
                    </div>
                    <div className="col-12 col-md-3">
                      <label for="exampleFormControlSelect1">Date To *</label>
                      <div className="row">
                        <div class="col-4">
                          <select
                            class="form-control"
                            id="nationalityEverDayTo"
                            onChange={(e) => [
                              setErrorNested(),
                              onSelectChangedNested(
                                e,
                                key,
                                "otherNationalityEverList"
                              ),
                            ]}
                            value={
                              formData?.otherNationalityEverList[key]
                                .nationalityEverDayTo
                            }
                          >
                            {}
                            <option value="">Day</option>
                            {createDaysOfMonth()}
                          </select>
                        </div>
                        <div class="col-4">
                          <select
                            class="form-control"
                            id="nationalityEverMonthTo"
                            onChange={(e) => [
                              setErrorNested(""),
                              onSelectChangedNested(
                                e,
                                key,
                                "otherNationalityEverList"
                              ),
                            ]}
                            value={
                              formData?.otherNationalityEverList[key]
                                .nationalityEverMonthTo
                            }
                          >
                            <option value="">Month</option>
                            <option value="Jan">Jan</option>
                            <option value="Feb">Feb</option>
                            <option value="Mar">Mar</option>
                            <option value="Apr">Apr</option>
                            <option value="May">May</option>
                            <option value="Jun">Jun</option>
                            <option value="Jul">Jul</option>
                            <option value="Aug">Aug</option>
                            <option value="Sep">Sep</option>
                            <option value="Oct">Oct</option>
                            <option value="Nov">Nov</option>
                            <option value="Dec">Dec</option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select
                            class="form-control"
                            id="nationalityEverYearTo"
                            onChange={(e) => [
                              onSelectChangedNested(
                                e,
                                key,
                                "otherNationalityEverList"
                              ),
                              setErrorNested(),
                            ]}
                            value={
                              formData?.otherNationalityEverList[key]
                                .nationalityEverYearTo
                            }
                          >
                            {}
                            <option value="">Year</option>
                            {createYears()}
                          </select>
                        </div>
                      </div>
                      <small class="form-text " style={{ color: "red" }}>
                        {errorNested?.id === "citizenshipEverCountryTo" &&
                          errorNested.key === key &&
                          errorNested.message}
                      </small>
                    </div>
                    <div className="col-12 col-lg-2">
                      <div class="form-group">
                        <label
                          class="control-label"
                          style={{ color: "white" }}
                          for="btn"
                        >
                          .
                        </label>
                        {formData.otherNationalityEverList.length > 1 && (
                          <button
                            onClick={() => [
                              removeRowEver(key),
                              setErrorNested(),
                            ]}
                            type="button"
                            class="btn btn-danger form-control"
                          >
                            - Remove
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {key + 1 === formData?.otherNationalityEverList.length && (
                    <div className="col-12">
                      <div className="row pb-5">
                        <button
                          onClick={() => addRowEver()}
                          type="button"
                          class="btn btn-secondary form-control"
                        >
                          + Add Another
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ))}
        </div>

        <div className="col-12">
          <p _ngcontent-yph-c50="" class="save-exit-info">
            <span _ngcontent-yph-c50="" class="fa-lg">
              <i
                _ngcontent-yph-c50=""
                aria-hidden="true"
                class="fa fa-info-circle"
              ></i>
              &nbsp;{" "}
            </span>
            <span _ngcontent-yph-c50="">
              IMPORTANT: Information in relation to your application will be
              sent to the email address entered below.
            </span>
          </p>
        </div>

        <div className="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">Email Address *</label>
            <input
              type="text"
              id="email"
              class="form-control"
              placeholder="Email"
              onChange={(e) => [updateFormData(e), setError()]}
              value={formData?.email}
            />
            <small class="form-text " style={{ color: "red" }}>
              {error?.field === "email" && error.message}
            </small>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">
              Confirm Email Address *
            </label>
            <input
              type="text"
              id="emailConfirm"
              class="form-control"
              placeholder="Confirm Email"
              onChange={(e) => [updateFormData(e), setError()]}
              value={formData?.emailConfirm}
            />
          </div>
        </div>

        <div className="col-12 pt-5">
          <div class="modal-footer">
            <button
              onClick={() => (window.location.href = "/apply")}
              type="button"
              class="btn btn-primary"
            >
              {"<"} Previous Page
            </button>

            <button
              onClick={() => next()}
              type="button"
              class="btn btn-primary"
            >
              Next Page {">"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
export default ApplicantInformation;
